@import "utils";

$font_type0: 500 32px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 20px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type2: 600 20px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.rankingSection_layout {
  position: relative;
  height: min-content;
}
.rankingSection {
  @include flex-column;
  background-color: rgb(233, 231, 228);
}
.deco {
  background: var(--src) center center / contain no-repeat;
}
.deco_layout {
  position: absolute;
  height: 129px;
  width: 84px;
  right: 98px;
  transform: translateY(-60%);
  @include lg {
    width: 78px;
    right: 50px;
  }
  @include md {
    width: 72px;
    right: 41px;
  }
  @include sm {
    width: 68px;
  }
  @include xs {
    right: 11px;
  }
  @include xxs {
    width: 60px;
  }
  @include tn {
    right: 0px;
  }
}
.rankingContent {
  @include flex-column;
}
.rankingContent_layout {
  position: relative;
  height: min-content;
  width: 99.82%;
  max-width: 1200px;
  margin: 30px auto;
}
.innerBox {
  @include flex-column;
}
.innerBox_layout {
  position: relative;
  height: min-content;
  width: 100%;
}
.contentFlex {
  @include flex-column;
}
.contentFlex_layout {
  position: relative;
  height: min-content;
  margin: 0px 50px;
  width: calc(100% - 100px);
  @include lg {
    margin: 0px 45px;
    width: calc(100% - 90px);
  }
  @include md {
    margin: 0px 40px;
    width: calc(100% - 80px);
  }
  @include sm {
    margin: 0px 25px;
    width: calc(100% - 50px);
  }
  @include xs {
    margin: 0px 20px;
    width: calc(100% - 40px);
  }
  @include tn {
    margin: 0px 15px;
    width: calc(100% - 30px);
  }
}
.contentFlex_item {
  @include flex-column;
  position: relative;
  width: 100%;
}
.sectionTitle {
  display: flex;
}
.sectionTitle_layout {
  position: relative;
  height: min-content;
  margin: 2px 6px 0px 2px;
  @include lg {
    margin: 2px 5px 0px;
  }
}
.sectionTitle_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 181px;
}
.titleFlex {
  display: flex;
}
.titleFlex_layout {
  position: relative;
  flex-grow: 1;
  min-height: 40px;
  flex-shrink: 0;
}
.titleFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 1 32px;
}
.icon {
  background: var(--src) center center / contain no-repeat;
}
.icon_layout {
  position: relative;
  height: 36px;
  width: 36px;
  min-width: 36px;
  margin: 6.5px 0px;
  @include lg {
    margin: 6px 0px;
  }
  @include md {
    height: 32px;
    width: 32px;
    min-width: 32px;
    margin: 5px 0px;
  }
  @include xs {
    height: 28px;
    width: 28px;
    min-width: 28px;
  }
}
.titleFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
  @include xs {
    min-width: 8px;
  }
  @include xxs {
    min-width: 4px;
  }
}
.titleFlex_item1 {
  @include flex-column;
  position: relative;
}
.title {
  @include v-center-content;
  @include font-face($font_type0, rgb(117, 89, 58), 0px);
  @include web {
    font-size: 24px;
    text-align: left;
  }
  @include xs {
    font-size: 22px;
  }
}
.title_layout {
  position: relative;
  flex-grow: 1;
  min-height: 40px;
  flex-shrink: 0;
}
.contentFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 36.5px;
  @include md {
    min-height: 30px;
  }
  @include xxs {
    min-height: 25px;
  }
}
.carouselContainer {
  @include flex-column;
}
.carouselContainer_layout {
  position: relative;
  height: min-content;
  margin: 1.5px 0px 0px;
  width: 100%;
}
.innerContent {
  @include flex-column;
}
.innerContent_layout {
  position: relative;
  height: min-content;
  @include lg {
    margin: 5px 0px 0px;
  }
  width: 100%;
}
.innerContent_item {
  @include flex-column;
  position: relative;
  overflow: auto;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}


.innerContent_item::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}


.innerContent_item3 {
  @include flex-column;
  position: relative;
  overflow: visible;
  width: 100%;
}
.tabBtnFlex {
  display: flex;
  width: 100%;
}
.tabBtnFlex_layout {
  position: relative;
  height: min-content;
}
.tabBtnFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.tabTitle {
  @include v-center-content;
  justify-content: center;
  font: $font_type1;
  color: rgb(51, 51, 51);
  text-align: center;
  letter-spacing: 0px;
  @include lg {
    font-size: 15px;
    text-align: center;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.tabTitle_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 24.5px;
}
.tabBtnFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 31px;
  @include sm;
  @include xs {
    flex: 1 1 31px;
  }
}
.tabBtnFlex_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 184px;

  cursor: pointer;
}
.tabItem {
  @include flex-column;
}
.tabItem_layout {
  position: relative;
  height: min-content;
}
.tabTitle1 {
  @include h-center-content;
  font: $font_type2;
  color: rgb(117, 89, 58);
  text-align: center;
  letter-spacing: 0px;
  @include lg {
    font-size: 15px;
    text-align: center;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.tabTitle1_layout {
  position: relative;
  height: min-content;
  min-width: 123px;
  margin: 0px auto 20.5px;
  @include lg {
    margin: 0px auto 17.5px;
  }
  @include md {
    margin: 0px auto 14.5px;
  }
  @include tn {
    margin: 0px 0px 14.5px;
  }
}
.activeLine {
  background-color: rgb(117, 89, 58);
}
.activeLine_layout {
  position: relative;
  height: 4px;
  @include md {
    margin: 0px;
  }
  @include xs {
    margin: 0px 15px;
  }
  @include xxs {
    margin: 0px 8px;
  }
  @include tn {
    margin: 0px 4px;
  }
}
.tabBtnFlex_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 40px;
  @include sm;
  @include xs {
    flex: 1 1 40px;
  }
}
.innerContent_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 20px;
}
.group {
  @include flex-column;
}
.group_layout {
  position: relative;
  height: min-content;
  width: 100%;
}
.flex10_layout {
  position: relative;
  height: min-content;
  flex-shrink: 0;
  width: 100%;
  margin: 0px 0px;
  @include sm {
    width: 83.21%;
    margin: 0px auto;
  }
}
.icon4 {
  background: var(--src) center center / contain no-repeat;
}
.icon4_layout {
  position: absolute;
  z-index: 1;
  top: 114px;
  height: 44px;
  left: -22px;
  width: 44px;
  @include lg {
    top: 97px;
  }
  @include md {
    top: 104px;
  }
  @include sm {
    top: 148px;
    left: 24px;
    width: 40px;
  }
  @include xs {
    top: 121px;
    left: 18px;
  }
  @include xxs {
    top: 90px;
    left: 10px;
    width: 36px;
  }
  @include tn {
    top: 66px;
    left: 6px;
  }
}
.icon4_layout1 {
  position: absolute;
  z-index: 1;
  top: 114px;
  height: 44px;
  width: 44px;
  right: -22px;
  @include lg {
    top: 97px;
  }
  @include md {
    top: 104px;
  }
  @include sm {
    top: 148px;
    width: 40px;
    right: 24px;
  }
  @include xs {
    top: 121px;
    right: 18px;
  }
  @include xxs {
    top: 90px;
    width: 36px;
    right: 10px;
  }
  @include tn {
    top: 66px;
    right: 6px;
  }
}
.innerContent_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 60px;
  @include lg {
    min-height: 50px;
  }
  @include md {
    min-height: 40px;
  }
  @include xs {
    min-height: 30px;
  }
}
.moreBtn {
  @include flex-column;
  border: 2px solid rgb(117, 89, 58);
  border-radius: 22px 22px 22px 22px;
}
.moreBtn_layout {
  position: relative;
  height: min-content;
  width: 27.28%;
  margin: 0px auto;
  @include md {
    width: 30.16%;
  }
  @include sm {
    width: 39.62%;
  }
  @include xs {
    width: 47.63%;
  }
  @include xxs {
    width: 61.58%;
  }
  @include tn {
    width: 68.14%;
  }
}
.moreContent {
  display: flex;
}
.moreContent_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 11px auto;
  @include lg {
    margin: 10px auto;
  }
  @include xs {
    margin: 8px auto;
  }
}
.moreContent_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.moreText {
  @include v-center-content;
  justify-content: center;
  font: $font_type1;
  color: rgb(117, 89, 58);
  text-align: center;
  letter-spacing: 0px;
  @include lg {
    font-size: 15px;
    text-align: center;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.moreText_layout {
  position: relative;
  flex-grow: 1;
  min-height: 20px;
  flex-shrink: 0;
  margin: 0px 0px 2px 28.5px;
  @include lg {
    margin: 0px 0px 0px 23px;
  }
  @include md {
    margin: 0px 0px 0px 12px;
  }
  @include xxs {
    margin: 0px 0px 0px 23px;
  }
  @include tn {
    margin: 0px 0px 0px 20px;
  }
}
.moreContent_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
  @include sm {
    min-width: 17px;
  }
  @include xs {
    min-width: 12px;
  }
}
.moreContent_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 5px;
}

.leftArrow {
  position: absolute;
  top: 38%;
  left: 0px;

  transform: translate(-50%, -50%);
  z-index: 1;

  width: 40px;
  height: 40px;

  background: var(--src) center center / contain no-repeat;
  cursor: pointer;

  @include xs {
    left: 20px;
  }
}
.rightArrow {
  position: absolute;
  top: 38%;
  right: 0px;

  transform: translate(50%, -50%);
  z-index: 1;

  width: 40px;
  height: 40px;

  background: var(--src) center center / contain no-repeat;
  cursor: pointer;

  @include xs {
    right: 20px;
  }
}
