.page {
  position: relative;
  z-index: 2;
  padding-top: 90px;
  min-height: 100%;
  padding-bottom: 80px;
}

.toastOuterContainer {
  min-width: unset;
  white-space: nowrap;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 24px;
}

.toastClass {
  background-color: rgba(117, 89, 58, 0.8);
  width: min-content;
  min-height: 44px;
  border-radius: 84px
}

.bodyStyle {
  font-size: 16px;
  color: #fff;
  align-items: center;
  line-height: normal;

  text-align: center;
}

.appAlert {
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto;
  grid-gap: 5px;
  align-items: center;

  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #ac9b89;

  z-index: 1000;

  padding: 0px 5px;
}

.closeBtn {
  width: 30px;
  height: 30px;
}

.appIconContainer {
  width: 100%;
  height: 100%;

  border-radius: 10px;

  display: none;
}

.icon {
  width: 90%;
  height: 90%;
  object-fit: contain;
  border-radius: 10px;
}

.textContainer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  color: #fff;
}

.headerText {
  font-size: 16px;
  font-weight: 600;
}

.alertDescription {
  font-size: 12px;
}

.goBtnContainer {
  width: 60px;
  height: 70%;

  background-color: #f1bf70;
  border-radius: 28.5px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.globalLoading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1000;

  cursor: progress;

  overflow: auto;
  overscroll-behavior: none;
}

.globalLoading .loadingContainer {
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.globalLoading .loadingContainer .inner {
  width: 60px;
  height: 60px;
}

@media screen and (min-width: 350px) {
  .appIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .appAlert {
    grid-template-columns: auto 60px minmax(auto, 1fr) auto;
  }
}

@media screen and (min-width: 576px) {
  .appAlert {
    display: none;
  }
}