@import "utils";

$font_type4: 14px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.forgetPassword {
  @include flex-column;
  background: var(--src) center center / cover  no-repeat;
  background-color: rgb(242, 242, 242);
  border-radius: 10px;

  @include xs {
    height: 100%;
  }
}
.forgetPassword_layout {
  position: relative;
  overflow: hidden;
  min-height: 644px;
  flex-shrink: 0;
}
.contentContainer {
  @include flex-column;
}
.contentContainer_layout {
  position: relative;
  height: min-content;
  margin: 10px;

  @include xs {
    height: 100%;
  }
}
.closeBtn {
  background: var(--src) center center / contain no-repeat;

  cursor: pointer;
}
.closeBtn_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
}
.contentFlex {
  @include flex-column;
}
.contentFlex_layout {
  position: relative;
  width: 62.96%;
  min-width: 300px;
  margin: 9px auto 19px;

  @include xs {
    margin: auto;
  }
}
.mainLogo {
  background: var(--src) center center / contain no-repeat;
}
.mainLogo_layout {
  position: relative;
  height: 147px;
  width: 57.39%;
  margin: 0px auto;
}
.contentFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 25px;
}
.inputsContainer_layout {
  position: relative;
  height: min-content;
  width: 100%;
  margin: 0px 0px;
}
.contentFlex_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 18px;
}
.policyBtns {
  @include h-center-content;
  font: $font_type4;
  color: rgb(174, 174, 174);
  text-align: center;
  letter-spacing: 0px;
  @include xs {
    font-size: 12px;
    text-align: center;
  }
  @include tn {
    font-size: 10px;
  }
}
.policyBtns_layout {
  position: relative;
  height: min-content;
}

.errorAlert {
  display: flex;
  justify-content: flex-end;
  font: $font_type4;
  color: rgb(255, 93, 93);
  text-align: right;
  text-decoration: underline;
  letter-spacing: 0px;
  @include xs {
    font-size: 12px;
    text-align: right;
  }
  @include tn {
    font-size: 11px;
  }
}
.errorAlert_layout {
  position: relative;
  height: min-content;
  margin: 2px 0px 0px;
}


@import "utils";

$font_type0: 500 24px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type2: 16px/0.62 "Noto Sans TC", Helvetica, Arial, serif;
$font_type3: 600 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.newPasswordComponent {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px 0px rgba(105, 47, 10, 0.43137254901960786);
  border-radius: 10px 10px 10px 10px;
}
.newPasswordComponent_layout {
  position: relative;
  height: min-content;
}
.componentContainer {
  @include flex-column;
}
.componentContainer_layout {
  position: relative;
  height: min-content;
  margin: 20px 16px;
}
.componentTitle {
  @include h-center-content;
  font: $font_type0;
  color: rgb(51, 51, 51);
  text-align: center;
  letter-spacing: 0px;
  @include md {
    text-align: center;
  }
  @include xs {
    font-size: 20px;
  }
  @include tn {
    font-size: 18px;
  }
}
.componentTitle_layout {
  position: relative;
  height: min-content;
}
.codeInputContainer {
  @include flex-column;
}
.codeInputContainer_layout {
  position: relative;
  height: min-content;
  margin: 33.5px 9px 0px;
  @include xs {
    margin: 33.5px 0px 0px;
  }
}
.codeFlex {
  display: flex;
  align-items: center;
}
.codeFlex_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 0px 5px;
  @include xs {
    margin: 0px;
  }
}
.title {
  @include v-center-content;
  @include font-face($font_type1, rgb(51, 51, 51), 0px);
  @include xs {
    font-size: 14px;
    text-align: left;
  }
  @include tn {
    font-size: 12px;
  }
}
.title_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
}
.codeFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 113px;
}
.placeholder {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type2;
  color: #000;
  text-align: right;
  letter-spacing: 0px;
  &::placeholder {
    color: rgb(174, 174, 174);
  }

  outline: none !important;
  @include xs {
    font-size: 14px;
    text-align: right;
  }
  @include tn {
    font-size: 12px;
  }
}
.placeholder_layout {
  position: relative;
  min-height: 10px;
  flex-shrink: 0;
}
.divider {
  background-color: rgb(237, 237, 237);
}
.divider_layout {
  position: relative;
  height: 1px;
  margin: 13.5px 0px 0px;
}
.passwordContainer {
  @include flex-column;
}
.passwordContainer_layout {
  position: relative;
  height: min-content;
  margin: 13.5px 0px 0px 9px;
  @include xs {
    margin: 13.5px 0px 0px;
  }
}
.passwordBox {
  @include flex-column;
}
.passwordBox_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 0px 5px;
  @include xs {
    margin: 0px;
  }
}
.passwordFlex {
  display: flex;
}
.passwordFlex_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 3px;
}
.passwordFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.title_layout1 {
  position: relative;
  height: min-content;
  margin: 13px 0px 0px;
  @include xs {
    margin: 11px 0px 0px;
  }
  @include tn {
    margin: 10px 0px 0px;
  }
}
.passwordFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 29px;
  @include xs {
    flex: 0 1 44px;
  }
  @include tn {
    flex: 0 1 auto;
  }
}
.passwordFlex_item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.placeholder1 {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type2;
  color: rgb(174, 174, 174);
  text-align: right;
  letter-spacing: 0px;
  width: 100%;
  height: 100%;
  @include xs {
    font-size: 14px;
    text-align: right;
  }
  @include tn {
    font-size: 12px;
  }
}
.passwordFlex_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.showPassword {
  background: var(--src) center center / contain no-repeat;
}
.showPassword_layout {
  position: relative;
  height: 22px;
  width: 22px;
  min-width: 22px;
  @include xs {
    height: 20px;
    width: 20px;
    min-width: 20px;
    margin: 0px;
  }
  @include tn {
    height: 18px;
    width: 18px;
    min-width: 18px;
  }
}
.divider_layout1 {
  position: relative;
  height: 1px;
  margin: 2px 9px 0px 0px;
  @include max {
    margin: 2px 5px 0px 0px;
  }
  @include xs {
    margin: 2px 0px 0px;
  }
}
.componentContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 1 47px;
}
.passwordConfirmContainer {
  @include flex-column;
}
.passwordConfirmContainer_layout {
  position: relative;
  height: min-content;
  margin: 13.5px 0px 0px;
  @include xs {
    margin: 13.5px 0px 0px;
  }
}
.passwordConfirmBox {
  @include flex-column;
}
.passwordConfirmBox_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 0px 5px;
  @include xs {
    margin: 0px;
  }
}
.passwordConfirmFlex {
  display: flex;
}
.passwordConfirmFlex_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 3px;
}
.passwordConfirmFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.title_layout2 {
  position: relative;
  height: min-content;
  margin: 13px 0px 0px;
  @include xs {
    margin: 11px 0px 0px;
  }
  @include tn {
    margin: 10px 0px 0px;
  }
}
.passwordConfirmFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 29px;
  @include xs {
    flex: 0 1 44px;
  }
  @include tn {
    flex: 0 1 auto;
  }
}
.passwordConfirmFlex_item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.placeholder2 {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type2;
  color: rgb(174, 174, 174);
  text-align: right;
  letter-spacing: 0px;
  width: 100%;
  height: 100%;
  @include xs {
    font-size: 14px;
    text-align: right;
  }
  @include tn {
    font-size: 12px;
  }
}
.passwordConfirmFlex_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.divider_layout2 {
  position: relative;
  height: 1px;
  margin: 2px 9px 0px 0px;
  @include max {
    margin: 2px 5px 0px 0px;
  }
  @include xs {
    margin: 2px 0px 0px;
  }
}
.componentContainer_item1 {
  @include flex-column;
  position: relative;
}

.submitBtn {
  @include flex-column;
  background-color: rgb(241, 191, 112);
  border-radius: 22px 22px 22px 22px;

  cursor: pointer;
}
.submitBtn_layout {
  position: relative;
  height: min-content;
  margin: 20px 22px 6px 21px;
}
.submitText {
  @include h-center-content;
  font: $font_type3;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
  @include xs {
    font-size: 14px;
    text-align: center;
  }
  @include tn {
    font-size: 12px;
  }
}
.submitText_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 12px auto;
}

.forgetBtn {
  @include flex-column;
  background-color: #fff;
  border-radius: 22px 22px 22px 22px;

  cursor: pointer;
}
.forgetBtn_layout {
  position: relative;
  height: min-content;
  margin: 0 22px 6px 21px;
}
.forgetText {
  @include h-center-content;
  font: $font_type3;
  color: rgb(241, 191, 112);
  text-align: center;
  letter-spacing: 0px;
  @include xs {
    font-size: 14px;
    text-align: center;
  }
  @include tn {
    font-size: 12px;
  }
}
.forgetText_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 12px auto;
}


.noBorder {
  border: none !important;
}

.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}