@import "utils";

$font_type0: 16px/1.5 "Noto Sans TC", Helvetica, Arial, serif;

.bannerSection_layout {
  position: relative;
  height: min-content;
}
.bannerSection {
  @include flex-column;
  background: var(--src) center bottom / contain no-repeat;
}
.bannerFlexBox {
  @include flex-column;
}
.bannerFlexBox_layout {
  position: relative;
  height: min-content;
  width: 100%;
  margin: 10px auto;
  @include xxl {
    width: 100%;
    margin: 10px 0px;
  }
  @include lg {
    margin: 12px 0px;
  }
  @include md {
    margin: 15px 0px;
  }
  @include sm {
    margin: 20px 0px;
  }
  @include xxs {
    margin: 15px 0px;
  }
}
.bannerFlexBox_item {
  @include flex-column;
  position: relative;
}
.block8 {
  @include flex-column;
}
.block8_layout {
  position: relative;
  flex-grow: 1;
  min-height: 428px;
  flex-shrink: 0;
}
.flex4 {
  @include flex-column;
}
.flex4_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 0px 1px;
  @include xxl {
    margin: 0px;
  }
  @include lg {
    margin: 0px 0px 0px 5px;
  }
}
.flex4_item {
  @include flex-column;
  position: relative;
  @include md {
    flex: 0 1 auto;
  }
}
.flex5 {
  display: flex;
}
.flex5_layout {
  position: relative;
  height: min-content;
  width: 100%;
  margin: 0px 0px;
}
.flex5_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include max {
    order: 1;
  }
}
.flex5_item {
  @include flex-column;
  position: relative;
  flex: 0 1 704px;
  @include max {
    order: 4;
  }
}
.group {
  @include flex-column;
}
.decorator {
  background: var(--src) center center / contain no-repeat;
  filter: drop-shadow(0px 0px 9px rgba(49, 36, 22, 0.403921568627451));
  border-radius: 15px 15px 15px 15px;
}
.decorator_layout {
  position: absolute;
  top: 40px;
  height: 317px;
  left: -387px;
  width: 564px;
}
.group_layout {
  position: relative;
  height: min-content;
}
.decorator_layout1 {
  position: absolute;
  top: 40px;
  height: 317px;
  width: 564px;
  right: -386px;
}
.image2 {
  background: var(--src) center center / cover no-repeat;
  filter: drop-shadow(0px 0px 9px rgba(49, 36, 22, 0.403921568627451));
  border-radius: 15px 15px 15px 15px;
}
.image2_layout {
  position: relative;
  height: 396px;
  width: 100%;
  margin: 0px 0px;
  @include sm {
    height: 360px;
  }
  @include xs {
    height: 330px;
  }
  @include xxs {
    height: 280px;
  }
}
.flex5_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 1px;
  @include max {
    order: 5;
  }
  @include xl {
    flex: 0 1 auto;
  }
}
.flex5_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 38px;
  @include max {
    order: 6;
  }
}
.cover_block {
  @include flex-column;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0px 13px 13px 0px;
}
.cover_block_layout {
  position: relative;
  height: min-content;
  margin: 10px 0px;
  @include xl {
    margin: 145px 0px 10px;
  }
}
.image3 {
  background: var(--src) center center / contain no-repeat;
}
.image3_layout {
  position: relative;
  height: 19px;
  width: 9px;
  min-width: 9px;
  margin: 25px 13px 24px 16px;
  @include lg {
    margin: 22px 11px 21px 14px;
  }
  @include md {
    margin: 19px 10px 18px 12px;
  }
  @include sm {
    margin: 16px 8px 15px 10px;
  }
  @include xs {
    margin: 14px 7px 14px 9px;
  }
  @include xxs {
    margin: 13px 7px 12px 8px;
  }
  @include tn {
    margin: 12px 6px 11px 8px;
  }
}
.flex5_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 1px;
  @include max {
    order: 3;
  }
  @include xl {
    flex: 0 1 auto;
  }
}
.flex5_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 38px;
  @include max {
    order: 2;
  }
}
.cover_block1 {
  @include flex-column;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0px 13px 13px 0px;
  transform: rotate(180deg);
}
.cover_block1_layout {
  position: relative;
  height: min-content;
  margin: 10px 0px;
  @include xl {
    margin: 145px 0px 10px;
  }
}
.flex5_spacer3 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include max {
    order: 7;
  }
}
.flex4_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 22px;
}
.flex4_item1 {
  @include flex-column;
  position: relative;
}
.flex6 {
  display: flex;
}
.flex6_layout {
  position: relative;
  height: min-content;
  width: 65px;
  min-width: 65px;
  margin: 0px auto;
}
.flex6_item {
  @include flex-column;
  position: relative;
  flex: 0 1 10px;
}
.icon8 {
  background: var(--src) center center / contain no-repeat;
}
.icon8_layout {
  position: relative;
  height: 10px;
  width: 10px;
  min-width: 10px;
}
.flex6_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 6px;
}
.flex6_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 6px;
}
.icon7 {
  background: var(--src) center center / contain no-repeat;
}
.icon7_layout {
  position: relative;
  height: 6px;
  width: 6px;
  min-width: 6px;
  margin: 2px 0px;
  @include lg {
    margin: 5px 0px;
  }
}
.flex6_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 7px;
}
.flex6_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 6px;
}
.flex6_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 8px;
}
.flex6_item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 6px;
}
.flex6_spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 7px;
}
.flex6_item4 {
  @include flex-column;
  position: relative;
  flex: 0 1 6px;
}
.bannerFlexBox_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 40px;
  @include lg {
    flex: 0 1 40px;
  }
  @include md {
    flex: 0 1 35px;
  }
  @include sm {
    flex: 0 1 30px;
  }
  @include xs {
    flex: 0 1 25px;
  }
  @include tn {
    flex: 0 1 20px;
  }
}
.description {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  font: $font_type0;
  color: rgb(117, 89, 58);
  text-align: center;
  letter-spacing: 0px;
  white-space: pre-wrap;
  @include lg {
    font-size: 15px;
    text-align: center;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
  @include tn {
    font-size: 10px;
  }
}
.description_box {
  @include h-center-content;
  @include lg {
    align-items: flex-start;
    justify-content: center;
  }
  @include md {
    align-items: flex-start;
    justify-content: center;
  }
  @include sm {
    align-items: flex-start;
    justify-content: center;
  }
  @include xxs {
    align-items: flex-start;
    justify-content: center;
  }
  @include tn {
    align-items: flex-start;
    justify-content: center;
  }
}
.description_box_layout {
  position: relative;
  height: min-content;
  width: 56.39%;
  margin: 0px auto 6px;
  @include lg {
    margin: 0px auto 5px;
  }
  @include md {
    width: 100%;
    margin: 0px 0px 5px;
  }
}
.bannerFlexBox_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 10px;
  @include lg {
    flex: 0 1 10px;
  }
  @include md {
    flex: 0 1 10px;
  }
  @include sm {
    flex: 0 1 10px;
  }
  @include xs {
    flex: 0 1 0px;
  }
}

.zIndex {
  z-index: 2000;
}