#player-animate {
  // open hide 切換.
  .play-open-enter {
    display: block;
    opacity: 0;
  }

  .play-open-enter-active {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }

  .play-open-enter-done {
    display: block;
  }

  .play-open-exit {
    display: block;
    opacity: 1;
  }

  .play-open-exit-active {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  .play-open-exit-done {
    display: none;
  }

  // scroll top scroll bottom 切換.
  .play-scroll-enter {
    display: grid;
    opacity: 0;
    transform: translateY(100%);
  }

  .play-scroll-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  .play-scroll-enter-done {
    display: grid;
  }

  .play-scroll-exit {
    display: grid;
    opacity: 1;
    transform: translateY(0%);
  }

  .play-scroll-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  .play-scroll-exit-done {
    display: none;
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 大小螢幕切換.
  .big-screen-enter {
    display: grid;
    opacity: 0;
    transform: translateY(100%);
  }

  .big-screen-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  .big-screen-enter-done {
    display: grid;
    opacity: 1;
    transform: translateY(0%);
  }

  .big-screen-exit {
    display: grid;
    opacity: 1;
    transform: translateY(0%);
  }

  .big-screen-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  .big-screen-exit-done {
    display: none;
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
}
