/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import "utils";

$font_type0: 600 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 16px/2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type2: 14px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.footerContainer {
  @include flex-column;
  background-color: rgb(62, 58, 57);
}
.footerContainer_layout {
  position: relative;
  height: min-content;
}
.contentContainer {
  @include flex-column;
}
.contentContainer_layout {
  position: relative;
  height: min-content;
  width: 88.5%;
  margin: 30px auto;
  @include lg {
    width: 91.12%;
    margin: 26px auto;
  }
  @include md {
    width: 93.19%;
    margin: 22px auto;
  }
  @include sm {
    width: 94.8%;
    margin: 19px auto;
  }
  @include xs {
    width: 96.05%;
    margin: 17px auto;
  }
  @include xxs {
    width: 97.01%;
    margin: 15px auto;
  }
  @include tn {
    width: 97.74%;
    margin: 14px auto;
  }
}
.contentFlexBox {
  @include flex-column;
}
.contentFlexBox_layout {
  position: relative;
  height: min-content;
  width: 99.81%;
  max-width: 1062px;
  margin: 0px auto;
  @include lg {
    width: 99.86%;
  }
  @include md {
    width: 99.89%;
  }
  @include sm {
    width: 99.92%;
  }
  @include xs {
    width: 99.94%;
  }
  @include xxs {
    width: 99.96%;
  }
  @include tn {
    width: 99.97%;
  }
}
.mainContent {
  @include flex-column;
}
.mainContent_layout {
  position: relative;
  height: min-content;
  margin: 11px 1px 0px;
}
.mainFlexBox {
  display: flex;
  @include sm {
    flex-wrap: wrap;
    row-gap: 20px;
  }
}
.mainFlexBox_layout {
  position: relative;
  height: min-content;
  @include lg {
    margin: 10px 5px 0px;
  }
  @include md {
    margin: 8px 5px 0px;
  }
  @include sm {
    margin: 7px 5px 0px;
  }
  @include xs {
    margin: 6px 5px 0px;
  }
  @include xxs {
    margin: 6px 13px 0px;
  }
  @include tn {
    margin: 5px 13px 0px;
  }
}
.mainFlexBox_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 99px;
  @include sm {
    flex: 0 0 calc(50% - 10px);
    min-width: unset;
  }
}
.aboutUs {
  @include flex-column;
}
.aboutUs_layout {
  position: relative;
  height: min-content;
}
.aboutUsTitle {
  @include font-face($font_type0, rgb(255, 255, 255), 0px);
  @include lg {
    font-size: 15px;
    text-align: left;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.aboutUsTitle_layout {
  position: relative;
  height: min-content;
}
.footerContent {
  @include font-face($font_type1, rgb(174, 174, 174), 0px);
  @include lg {
    font-size: 15px;
    text-align: left;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}

.footerContent_layout {
  position: relative;
  height: min-content;
}


.footerContent_layoutFirst {
  position: relative;
  height: min-content;
  margin: 9px 0px 0px;
  @include lg {
    margin: 8px 0px 0px;
  }
  @include md {
    margin: 7px 0px 0px;
  }
  @include sm {
    margin: 6px 0px 0px;
  }
  @include xs {
    margin: 5px 0px 0px;
  }
}
.row2 {
  @include flex-column;
}
.row2_layout {
  position: relative;
  height: min-content;
}
.footerContent_layout1 {
  position: relative;
  height: min-content;
}
.block9 {
  @include flex-column;
  background-color: rgb(241, 191, 112);
  border: 1px solid rgb(148, 118, 86);
  border-radius: 3px 3px 3px 3px;
}
.block9_layout {
  position: absolute;
  top: 4px;
  height: 24px;
  left: 90px;
  @include md {
    top: 3px;
    height: 22px;
    left: 85px;
  }
  @include sm {
    height: 20px;
    left: 75px;
  }
  @include xxs {
    height: 18px;
    left: 70px;
  }
  @include tn {
    top: 4px;
  }
}
.text_body {
  @include v-center-content;
  justify-content: center;
  font: $font_type2;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
  white-space: nowrap;
  @include md {
    font-size: 13px;
    text-align: center;
  }
  @include sm {
    font-size: 12px;
  }
  @include xxs {
    font-size: 11px;
  }
}
.text_body_layout {
  position: relative;
  flex-grow: 1;
  min-height: 17px;
  flex-shrink: 0;
  margin: 0px 5px;
}
.aboutUs_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 24px;
}
.mainFlexBox_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 194px;
  @include md;
  @include sm {
    flex: 0 0 20px;
  }
}
.mainFlexBox_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 134px;
  @include sm {
    flex: 0 0 calc(50% - 10px);
    min-width: unset;
  }
}
.helpCenter {
  @include flex-column;
}
.helpCenter_layout {
  position: relative;
  height: min-content;

}
.helpCenterTitle {
  @include font-face($font_type0, rgb(255, 255, 255), 0px);
  @include lg {
    font-size: 15px;
    text-align: left;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.helpCenterTitle_layout {
  position: relative;
  height: min-content;
}
.footerContent_layout2 {
  position: relative;
  height: min-content;
}
.footerContent_layout3 {
  position: relative;
  height: min-content;
}
.footerContent_layout4 {
  position: relative;
  height: min-content;
}
.mainFlexBox_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 158px;
  @include sm {
    display: none;
  }
}
.mainFlexBox_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 161px;
  @include sm {
    flex: 0 0 calc(50% - 10px);
  }
}
.socialMedia {
  @include flex-column;
}
.socialMedia_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 32px;
  @include lg {
    margin: 0px 0px 28px;
  }
  @include md {
    margin: 0px 0px 24px;
  }
  @include sm {
    margin: 0px 0px 20px;
  }
  @include xs {
    margin: 0px 0px 18px;
  }
  @include xxs {
    margin: 0px 0px 16px;
  }
  @include tn {
    margin: 0px 0px 15px;
  }
}
.socialMediaTitle {
  @include font-face($font_type0, rgb(255, 255, 255), 0px);
  @include lg {
    font-size: 15px;
    text-align: left;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.socialMediaTitle_layout {
  position: relative;
  height: min-content;
}
.flex131 {
  @include flex-column;
}
.flex131_layout {
  position: relative;
  height: min-content;
  margin: 9px 0px 0px;
  @include lg {
    margin: 8px 0px 0px;
  }
  @include md {
    margin: 7px 0px 0px;
  }
  @include sm {
    margin: 6px 0px 0px;
  }
  @include xs {
    margin: 5px 0px 0px;
  }
}
.fb {
  @include flex-column;
}
.fb_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 0px 5px;
}
.image7 {
  background: var(--src) center center / contain no-repeat;
}
.image7_layout {
  position: absolute;
  top: 0px;
  height: 20px;
  left: 0px;
  width: 10px;
  min-width: 10px;
  margin: 6px 0px 0px;
  @include lg {
    margin: 5px 0px 0px;
  }
}
.highlights1 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type1, rgb(174, 174, 174), 0px);
  white-space: pre-wrap;
  @include lg {
    font-size: 15px;
    text-align: left;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.highlights1_box {
  @include lg {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include md {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include sm {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include xxs {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.highlights1_box_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 0px 22px;
}
.ig {
  @include flex-column;
}
.ig_layout {
  position: relative;
  height: min-content;
  margin: 6px 0px 0px;
  @include lg {
    margin: 5px 0px 0px;
  }
}
.icon2 {
  background: var(--src) center center / contain no-repeat;
}
.icon2_layout {
  position: absolute;
  top: 0px;
  height: 20px;
  left: 0px;
  width: 20px;
  min-width: 20px;
  margin: 5px 0px 0px;
  @include lg {
    margin: 5px 0px 0px;
  }
  @include md {
    margin: 6px 0px 0px;
  }
  @include sm {
    margin: 5px 0px 0px;
  }
}
.highlights11 {
  @include font-face($font_type1, rgb(174, 174, 174), 0px);
  @include lg {
    font-size: 15px;
    text-align: left;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.highlights11_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 0px 27px;
}
.yt {
  @include flex-column;
}
.yt_layout {
  position: relative;
  height: min-content;
  margin: 4px 0px 0px;
  @include lg {
    margin: 5px 0px 0px;
  }
}
.image5 {
  background: var(--src) center center / contain no-repeat;
}
.image5_layout {
  position: absolute;
  top: 0px;
  height: 14px;
  left: 0px;
  width: 20px;
  min-width: 20px;
  margin: 8px 0px 0px;
  @include lg {
    margin: 7px 0px 0px;
  }
  @include md {
    margin: 8px 0px 0px;
  }
  @include sm {
    margin: 7px 0px 0px;
  }
  @include xs {
    margin: 6px 0px 0px;
  }
  @include tn {
    margin: 5px 0px 0px;
  }
}
.highlights12 {
  @include font-face($font_type1, rgb(174, 174, 174), 0px);
  @include lg {
    font-size: 15px;
    text-align: left;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.highlights12_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 0px 27px;
}
.flex131_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 7px;
}
.mainFlexBox_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 132px;
  @include sm {
    flex: 0 0 20px;
  }
}
.mainFlexBox_item3 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 123px;
  @include sm {
    flex: 0 0 calc(50% - 10px);
    min-width: unset;
  }
}
.downLoad {
  @include flex-column;
}
.downLoad_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 30px;
  @include lg {
    margin: 0px 0px 26px;
  }
  @include md {
    margin: 0px 0px 22px;
  }
  @include sm {
    margin: 0px 0px 19px;
  }
  @include xs {
    margin: 0px 0px 17px;
  }
  @include xxs {
    margin: 0px 0px 15px;
  }
  @include tn {
    margin: 0px 0px 14px;
  }
}
.downLoad_item {
  @include flex-column;
  position: relative;
}
.downLoadTitle {
  @include font-face($font_type0, rgb(255, 255, 255), 0px);
  @include lg {
    font-size: 15px;
    text-align: left;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.downLoadTitle_layout {
  position: relative;
  height: min-content;
}
.downLoad_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 20px;
  @include sm {
    min-height: 12px;
  }
}
.btnContainer {
  @include flex-column;
}
.btnContainer_layout {
  position: relative;
  height: min-content;
  margin: 0;
}
.appStore {
  background: var(--src) center center / contain no-repeat;
}
.appStore_layout {
  position: relative;
  height: 40px;
  width: 122px;
  min-width: 122px;
}
.googlePlay {
  background: var(--src) center center / contain no-repeat;
}
.googlePlay_layout {
  position: relative;
  height: 40px;
  width: 122px;
  min-width: 122px;
  margin: 7px 0px 0px;
  @include lg {
    margin: 6px 0px 0px;
  }
  @include md {
    margin: 5px 0px 0px;
  }
}
.image9 {
  background: var(--src) center center / contain no-repeat;
}
.image9_layout {
  position: absolute;
  height: 129px;
  width: 84px;
  right: 28px;
  top: -40px;
  transform: translateY(-60%);
  z-index: 1;
  @include lg {
    top: -38px;
    width: 76px;
    right: 32px;
  }
  @include md {
    top: -32px;
    width: 72px;
  }
  @include sm {
    top: -28px;
    width: 68px;
    right: 31px;
  }
  @include xs {
    top: -25px;
    right: 6px;
  }
  @include xxs {
    top: -24px;
    width: 60px;
  }
  @include tn {
    top: -22px;
  }
}
.line {
  background-color: rgb(101, 97, 97);
}
.line_layout {
  position: relative;
  height: 2px;
  margin: 51px 0px 0px 1px;
  @include lg {
    margin: 43px 0px 0px 5px;
  }
  @include md {
    margin: 35px 0px 0px 5px;
  }
  @include sm {
    margin: 28px 0px 0px 5px;
  }
  @include xs {
    margin: 25px 0px 0px 5px;
  }
  @include xxs {
    margin: 21px 0px 0px 5px;
  }
  @include tn {
    margin: 19px 0px 0px 5px;
  }
}
.contentFlexBox_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 8px;
}
.rightSection {
  display: flex;
  align-items: center;
  @include sm {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.rightSection_layout {
  position: relative;
  height: min-content;
  margin: 1px 0px 0px 2px;
  @include xs {
    margin: 1px 2px 0px;
  }
}
.rightSection_item {
  @include flex-column;
  position: relative;
  flex: 0 1 283px;
  @include sm {
    flex: 0 0 100%;
  }
}
.footerIcon {
  background: var(--src) center center / contain no-repeat;
}
.footerIcon_layout {
  position: relative;
  height: 16px;
}
.rightSection_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 429px;
  @include sm {
    display: none;
  }
}
.rightSection_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include sm {
    flex: 0 0 100%;
  }
}
.rightContent {
  display: flex;
  justify-content: flex-end;
  font: $font_type2;
  color: rgb(174, 174, 174);
  text-align: right;
  letter-spacing: 0px;
  white-space: pre-wrap;
  line-height: 1.5;
  @include sm {
    justify-content: center;
    text-align: center;
  }
  @include xs {
    font-size: 12px;
  }
  @include xxs {
    font-size: 11px;
  }
  @include tn {
    font-size: 10px;
  }
}
.rightContent_layout {
  position: relative;
  flex-grow: 1;
  min-height: 17px;
  flex-shrink: 0;
}
