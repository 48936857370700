@import "utils";

$font_type0: 14px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 600 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type2: 500 18px/1.5 "Noto Sans TC", Helvetica, Arial, serif;
$font_type3: 14px "Noto Sans TC", Helvetica, Arial, serif;
$font_type4: 14px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.courseCard {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px 10px 10px 10px;
}
.courseCard_layout {
  position: relative;
  height: calc(100% - 20px);
  margin: 10px;

  overflow: hidden;
}
.cardLink {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.courseCardAudiobook_item {
  @include flex-column;
  position: relative;
}
.upperContainer {
  @include flex-column;
}
.upperContainer_layout {
  position: relative;
  height: min-content;
}
.imageContainer {
  display: flex;
  overflow: hidden;
  width: 100%;
  padding-top: 75%;
  flex-direction: row;
}
.imageContainer_layout {
  position: relative;
  flex-grow: 1;
}
.typeIcon {
  background: var(--src) center center / contain no-repeat;
}
.typeIcon_layout {
  position: absolute;
  z-index: 1;
  top: 10px;
  height: 24px;
  width: 24px;
  right: 10px;
}

.videoThumbnailContainer {
  @include flex-column;
  border-radius: 10px 10px 0px 0px;
}
.videoThumbnailContainer_layout {
  position: absolute;
  top: 0;
  left: 0;
  height: min-content;
  width: 100%;
  padding-top: 75%;
  overflow: hidden;
}
.videoThumbnail {
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  height: 100%;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.videoThumbnail_layout {
  position: absolute;
  top: 0;
  left: 0;
}


.audiobookThumbnailContainer {
  display: flex;
  overflow: hidden;
  width: 100%;
  padding-top: 75%;
  border-radius: 10px 10px 0px 0px;
  filter: drop-shadow(5px 0px 10px rgba(0, 0, 0, 0.3));
}
.audiobookThumbnailContainer_layout {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.audiobookThumbnail {
  position: absolute;
  top: 0;
  left: 12.5%;
  width: 75%;
  height: 100%;
  margin: 0 auto;
  // height: auto;
  vertical-align: top;
  object-fit: cover;
  object-position: center top;
}

.image {
  background: var(--src) center center / contain no-repeat;
  border-radius: 12px 12px 12px 12px;
}
.image_layout {
  position: absolute;
  top: 5%;
  bottom: 0;
  height: 90%;
  width: 75%;
  right: 8px;
}

.audioThumbnailContainer {
  @include flex-column;
  flex-direction: row;
  border-radius: 10px 0px 0px 0px;
  width: 100%;
}
.audioThumbnailContainer_layout {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.audioThumbnail {
  border-radius: 10px 0px 0px 0px;
  width: 75%;
  height: 100%;
  vertical-align: top;
  object-fit: contain;
  object-position: center center;
}
.audioThumbnail_layout {
  position: relative;
}

.tagsContainer {
  position: absolute;
  bottom: 10px;
  right: 10px;

  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 5px;
}
.block9 {
  background-color: rgb(117, 89, 58);
  border: 1px solid rgb(148, 118, 86);
  border-radius: 3px 3px 3px 3px;
}
.block9_layout {
  position: relative;
}

.discountCard {
  background-color: #ff5d5d;
  border: 1px solid #ff5d5d;
  border-radius: 3px 3px 3px 3px;
  position: relative;
}

.discountText {
  @include v-center-content;
  justify-content: center;
  font: $font_type0;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
  position: relative;
  height: min-content;
  margin: 3.5px 2px;
}

.block10 {
  background-color: rgb(128, 128, 128);
  border: 1px solid rgb(169, 169, 169);
  border-radius: 3px 3px 3px 3px;
}
.block10_layout {
  position: relative;
}
.text_body {
  @include v-center-content;
  justify-content: center;
  font: $font_type0;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
}
.text_body_layout {
  position: relative;
  height: min-content;
  margin: 3.5px 2px;
}
.tagsContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
}
.group {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
}
.group_layout {
  position: absolute;
  top: 20px;
  height: min-content;
  left: 0px;
  min-width: 0px;
  width: fit-content;
}
.text_body1 {
  display: flex;
  justify-content: flex-end;
  font: $font_type1;
  color: rgb(117, 89, 58);
  text-align: right;
  letter-spacing: 0px;
}
.text_body1_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 7px 42px 7px 12px;
}
.bottomContainer {
  @include flex-column;
}
.bottomContainer_layout {
  position: relative;
  flex-grow: 1;
  margin: 20px 15px 21px;
  @include md {
    margin: 16px 15px;
  }
  @include xs {
    margin: 14px 15px;
  }
  @include tn {
    margin: 12px 15px;
  }
}
.titleOuter {
  @include flex-column;
}
.titleOuter_layout {
  position: relative;
  height: 100%;
}
.titleContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
}
.titleContainer_layout {
  position: relative;
}
.title {
  display: -webkit-box;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type2, rgb(51, 51, 51), 0px);
  white-space: pre-wrap;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @include web {
    font-size: 16px;
    text-align: left;
  }
}
.title_box {
  @include v-center-content;
  @include lg {
    align-items: center;
    justify-content: flex-start;
  }
  @include sm {
    align-items: center;
    justify-content: flex-start;
  }
  @include tn {
    align-items: center;
    justify-content: flex-start;
  }
}
.title_box_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.image1 {
  background: var(--src) center center / contain no-repeat;
}
.image1_layout {
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin: 5px 0px 30px;
  @include lg {
    margin: 4px 0px 30px;
  }
  @include md {
    margin: 4px 0px 22px;
  }
  @include sm {
    width: 18px;
    min-width: 18px;
    margin: 3px 0px 19px;
  }
  @include xs {
    margin: 3px 0px 17px;
  }
  @include xxs {
    width: 16px;
    min-width: 16px;
    margin: 3px 0px 15px;
  }
  @include tn {
    margin: 1px 0px 14px;
  }
}
.otherInfo {
  display: grid;
  grid-template-columns: 1fr minmax(30px, auto);
}
.otherInfo_layout {
  position: relative;
  height: min-content;
  margin: 10px 0px 0px;
  @include lg {
    margin: 10px 0px 0px;
  }
}
.authorName {
  @include v-center-content;
  @include font-face($font_type3, rgb(174, 174, 174), 0px);
  display: block;
  @include web {
    font-size: 12px;
  }
}
.authorName_layout {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 30px;
  @include sm {
    padding-left: 28px;
  }
  @include xxs {
    padding-left: 26px;
  }
}
.otherInfo_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 132px;
}
.otherInfo_item {
  @include flex-column;
  position: relative;
  align-items: center;
  justify-content: center;
}
.popularityContainer {
  display: flex;
}
.popularityContainer_layout {
  position: relative;
  height: min-content;
}
.popularityContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.viewIcon {
  background: var(--src) center center / contain no-repeat;
}
.viewIcon_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.popularityContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 3px;
}
.popularity {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type4;
  color: rgb(117, 89, 58);
  text-align: right;
  letter-spacing: 0px;
  @include web {
    font-size: 12px;
  }
}
.popularity_layout {
  position: relative;
  flex: 0 0 auto;
  min-height: 20px;
  flex-shrink: 0;
}
.notYetPurchaseContainer {
  background-color: #fff;
  border-color: rgb(169, 169, 169);
}

.notYetPurchase {
  color: rgb(128, 128, 128)
}