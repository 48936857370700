.list {
  width: 100%;
  height: 100%;


  .item {
    padding: 10px 10px 6px;
    border-bottom: 1px solid #cccccc;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    .content {
      width: calc(100% - 80px);

      .title {
        padding-bottom: 10px;
        width: 100%;

        font-size: 16px;
        font-weight: 600;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .text {
        width: 100%;

        font-size: 14px;
        font-weight: 400;

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    .time {
      width: 80px;
      font-size: 14px;
      text-align: center;
    }
    &.readBg {
      background-color: #f2f2f2;
    }

    &.read {
      .content {
        .title,
        .text {
          font-weight: normal;
        }
      }
    }
  }
}


.readAllBtn {
  margin-top: 10px;
  padding: 10px 10px 10px 0;
  text-align: right;
  color: #75593a;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  background-color: #f2f2f2;
}

.divider {
  background-color: #f2f2f2;
  height: 44px;
  width: 100%;
  margin-top: 10px;
}

.announceItem {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1px;
  padding: 10px;
  border-bottom: 1px solid #f2f2f2;
}

.announceTitle {
  line-height: 1.2rem;
  max-height: 2.4rem;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.time {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  font-size: 12px;
  text-align: center;
}