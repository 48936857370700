@import "utils";

$font_type0: 20px/1.5 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 16px/1.5 "Noto Sans TC", Helvetica, Arial, serif;
$font_type2: 14px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type3: 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.recommendCard {
  display: flex;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09803921568627451);
  border-radius: 10px 10px 10px 10px;

  cursor: pointer;
}
.recommendCard_layout {
  position: relative;
  min-height: 100px;
  flex-shrink: 0;
}
.recommendCard_item {
  @include flex-column;
  position: relative;
  flex: 1 1 431px;
}
.innerContent {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.innerContent_layout {
  position: relative;
  height: 100%;
  margin: 11.5px 20px 11px;
  @include lg {
    margin: 10px 18px;
  }
  @include md {
    margin: 9px 15px 8px;
  }
  @include sm {
    margin: 13px;
  }
  @include xs {
    margin: 13px 11px 6px;
  }
  @include xxs {
    margin: 10px;
  }
}
.titleContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
}
.titleContainer_layout {
  position: relative;
  height: min-content;
}
.chapterTitle {
  display: -webkit-box;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type0, rgb(51, 51, 51), 0px);
  font-size: 18px;
  white-space: pre-wrap;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @include lg {
    font-size: 18px;
    text-align: left;
  }
  @include web {
    font-size: 16px;
  }
}
.chapterTitle_box {
  @include lg {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include md {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include sm {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include xxs {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.chapterTitle_box_layout {
  position: relative;
  height: min-content;
  @include max {
    flex-shrink: 0;
    margin: 0px;
  }
}
.iconContainer {
  @include flex-column;
  transform: translateY(5px);
}
.audioIcon {
  background: var(--src) center center / contain no-repeat;
}
.audioIcon_layout {
  width: 20px;
  height: 20px;
  min-width: 20px;
}
.audiobookIcon {
  background: var(--src) center center / contain no-repeat;
}
.audiobookIcon_layout {
  height: 20px;
  width: 20px;
  min-width: 20px;
}
.videoIcon {
  background: var(--src) center center / contain no-repeat;
}
.videoIcon_layout {
  height: 20px;
  width: 20px;
  min-width: 20px;
}
.bottomContainer {
  padding-left: 30px;
  grid-row: 3/4;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 5px;
}
.bottomContainer_layout {
  position: relative;
  height: min-content;
}
.authorName {
  @include font-face($font_type1, rgb(174, 174, 174), 0px);
  font-size: 14px;
  @include lg {
    text-align: left;
  }
  @include web {
    font-size: 12px;
  }
}
.authorName_layout {
  position: relative;
  width: 100%;
  min-height: 10px;
  margin: 1px 0px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.bottomContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.bottomContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 1 50px;
}
.newTag {
  @include flex-column;
  background-color: rgb(117, 89, 58);
  border: 1px solid rgb(148, 118, 86);
  border-radius: 3px 3px 3px 3px;
}
.newTag_layout {
  position: relative;
  height: min-content;
  width: 40px;
  min-width: 40px;
  margin: 0px 0px 0px auto;
}
.text_body {
  @include h-center-content;
  font: $font_type2;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
  @include md {
    font-size: 13px;
    text-align: center;
  }
  @include xs {
    font-size: 12px;
  }
}
.text_body_layout {
  position: relative;
  height: min-content;
  margin: 1.5px 2px 3.5px;
}
.bottomContainer_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
}
.onlyPurchaseTag {
  @include flex-column;
  background-color: rgb(117, 89, 58);
  border: 1px solid rgb(148, 118, 86);
  border-radius: 3px 3px 3px 3px;
}
.onlyPurchaseTag_layout {
  position: relative;
  height: min-content;
  width: 50px;
  min-width: 50px;
}
.bottomContainer_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 15px;
}
.bottomContainer_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 58px;
}
.popularityContainer {
  display: flex;
}
.popularityContainer_layout {
  position: relative;
  height: min-content;
}
.popularityContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.viewIcon {
  background: var(--src) center center / contain no-repeat;
}
.viewIcon_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.popularityContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 3px;
}
.popularity {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type3;
  color: rgb(117, 89, 58);
  text-align: right;
  letter-spacing: 0px;
  font-size: 14px;
  @include web {
    font-size: 12px;
  }
}
.popularity_layout {
  position: relative;
  flex: 0 0 auto;
  min-height: 20px;
  flex-shrink: 0;
}
