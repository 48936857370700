.alert {
  width: 100%;
  height: 100%;
  // 內容置中.
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // 定位.
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;

  display: none;

  &.active {
    display: flex;
  }

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .contentContainer {
    position: relative;

    .close {
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 1;
    }

    .buttons {
    }
  }
}
