.celebritySec .bullet {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 4px;
  background: #F9E5C6;
  border-radius: 50%;
  opacity: .4;
  cursor: pointer;
}

.celebritySec .active-bullet{
  position: relative;
  width: 6px;
  height: 6px;
  opacity: 1;
}
