.logoContainer {
  .container:last-child {
    padding-top: 20px;
  }

  .logo {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    background-color: #c4c4c4;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    text-align: center;
  }
}
