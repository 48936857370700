.app {
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
}

@media screen and (max-width: 576px) {
  .app {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overscroll-behavior: none;
    -webkit-overflow-scrolling:touch
  }
}