/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import "utils";

$font_type0: 14px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type4: 16px/0.6 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type2: 11px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.header {
  @include flex-column;
  transition: top .5s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.hideHeader {
  top: -100px;
}

.subscriptionBox_layout {
  position: relative;
  height: min-content;
}

.headerMainContainer_layout {
  position: relative;
  height: min-content;
}

.subscriptionBox {
  @include flex-column;
  background-color: rgb(227, 222, 216);
}

.subscriptionBox_layout {
  position: relative;
  height: min-content;
}

.subscriptionContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscriptionContent_layout {
  position: relative;
  height: min-content;
  width: 22%;
  max-width: 264px;
  margin: 8px auto;

  @include lg {
    width: 92.96%;
  }
}

.subscriptionContent_item {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
}

.memberIcon {
  background: var(--src) center center / contain no-repeat;
}

.memberIcon_layout {
  position: relative;
  height: 18px;
  width: 20px;
  min-width: 20px;

  @include md {
    width: 18px;
    min-width: 18px;
    margin: 0px 0px 0px 10px;
  }

  @include sm {
    margin: 0px 0px 0px 12px;
  }

  @include xs {
    width: 16px;
    min-width: 16px;
    margin: 0px 0px 0px 22px;
  }
}

.subscriptionContent_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8.5px;
}

.subscriptionContent_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}

.subscriptionText {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  font: $font_type0;
  color: rgb(118, 89, 58);
  text-align: center;
  letter-spacing: 0px;
  white-space: pre-wrap;

  @include md {
    font-size: 13px;
    text-align: center;
  }

  @include xs {
    font-size: 12px;
  }
}

.subscriptionText_box {
  @include h-center-content;

  @include md {
    align-items: flex-start;
    justify-content: center;
  }

  @include xs {
    align-items: center;
    justify-content: center;
  }
}

.subscriptionText_box_layout {
  position: relative;
  flex-grow: 1;
  min-height: 17px;
  flex-shrink: 0;
  margin: 1.5px 0px;
}

.subscriptionContent_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 2px;
}

.subscriptionContent_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 5px;
}

.arrowRight {
  background: var(--src) center center / contain no-repeat;
}

.arrowRight_layout {
  position: relative;
  flex-grow: 1;
  min-height: 20px;
  height: 100%;
  flex-shrink: 0;
  width: 10px;
  min-width: 20px;
}

.subscriptionContent_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 7px;

  @include md {
    display: none;
  }
}

.headerContainer {
  @include flex-column;
  background-color: rgb(242, 242, 242);
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
  border-width: 0px;
}

.headerContainer_layout {
  position: relative;
  height: min-content;
}

.mainContent {
  @include flex-column;
}

.mainContent_layout {
  position: relative;
  height: min-content;
  width: 95.83%;
  max-width: 1200px;
  margin: 17px auto;

  @include lg {
    width: 96.64%;
    margin: 0px auto;
  }

  @include md {
    width: calc(100% - 50px);
    margin: 0px auto;
  }
}

.contentFlexBox {
  display: flex;
}

.contentFlexBox_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  max-width: 1200px;

  @include lg {
    margin: 14px 0px;
  }

  @include md {
    margin: 11px 0px;
  }

  @include sm {
    margin: 9px 0px;
  }

  @include xs {
    margin: 8px 0px;
  }

  @include xxs {
    margin: 6px 0px;
  }

  @include tn {
    margin: 5px 0px;
  }
}

.contentFlexBox_item {
  @include flex-column;
  justify-content: center;
  position: relative;
  flex: 0 0 auto;

  @include md {
    flex: 0 1 auto;
    min-width: unset;
  }
}

.logoFlexBox {
  display: flex;
}

.logoFlexBox_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;

  @include xl {
    margin: 6px 0px;
  }
}

.logoFlexBox_item {
  @include flex-column;
  position: relative;
  flex: 0 1 24px;

  @include md {
    flex: 0 0 24px;
  }
}

.icon {
  background: var(--src) center center / contain no-repeat;
}

.icon_layout {
  position: relative;
  height: 30px;
  width: 24px;
  min-width: 24px;
}

.logoFlexBox_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 13px;

  @include xs {
    display: none;
  }
}

.logoFlexBox_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 165px;

  @include md {
    flex: 0 0 165px;
  }

  @include xs {
    display: none;
  }
}

.name {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}

.name_layout {
  position: relative;
  width: 165px;
  min-width: 165px;
}

.logoFlexBox_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 11px;

  @include md {
    flex: 0 1 auto;
    display: none;
  }
}

.logoFlexBox_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 72px;

  @include md {
    display: none;
  }
}

.nameCT {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}

.nameCT_layout {
  position: relative;
  width: 72px;
  min-width: 72px;
  margin: 10px 0px 0px;
}

.contentFlexBox_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 22px;

  @include max {
    display: none;
  }

  @include sm {
    min-width: 24px;
    display: flex;
  }
}

.contentFlexBox_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 20px;

  @include max {
    display: none;
  }

  @include sm {
    display: flex;
  }
}

.searchTiny {
  background: var(--src) center center / contain no-repeat;
}

.searchTiny_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin: 13px 0px;
}

.contentFlexBox_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 32px;

  @include md {
    min-width: 20px;
  }

  @include sm {
    min-width: 22px;
    display: none;
  }

  @include xxs {
    flex: 1 1 auto;
    min-width: 60px;
    display: flex;
  }

  @include tn {
    display: none;
  }
}

.contentFlexBox_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 399px;

  @include lg {
    flex: 1 1 auto;
  }

  @include sm {
    display: none;
  }
}

.searchContainer {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.058823529411764705);
  border-radius: 22px 22px 22px 22px;
}

.searchContainer_layout {
  position: relative;
  height: min-content;
  margin: 4px 0px;
}

.innerContent {
  display: flex;
}

.innerContent_layout {
  position: relative;
  flex-grow: 1;
  margin: 8.5px 20px;
}

.innerContent_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 20px;
}

.searchIcon {
  background: var(--src) center center / contain no-repeat;
}

.searchIcon_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
}

.innerContent_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 12px;
}

.innerContent_item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include md;
}

.placeholder {
  @include v-center-content;
  @include font-face($font_type4, rgb(174, 174, 174), 0px);
}

.placeholder_layout {
  position: relative;
  flex-grow: 1;
}

.contentFlexBox_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 32px;

  @include md {
    flex: 0 1 24px;
  }

  @include sm {
    flex: 1 1 22px;
  }

  @include xs {
    flex: 1 1 auto;
  }
}

.contentFlexBox_item3 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}

.myCourse {
  @include v-center-content;
  @include font-face($font_type1, rgb(117, 89, 58), 0px);

  @include lg {
    font-size: 15px;
    text-align: left;
  }

  @include sm {
    font-size: 14px;
  }

  @include xxs {
    font-size: 12px;
  }
}

.myCourse_layout {
  position: relative;
  flex-grow: 1;
  min-height: 20px;
  flex-shrink: 0;
  margin: 0px 2px 0px 0px;
}

.contentFlexBox_spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 24px;

  @include md {
    flex: 0 1 10px;
  }
}

.contentFlexBox_item4 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 44px;

  @include xxs {
    min-width: 39px;
  }

  @include tn {
    min-width: 33px;
  }
}

.contentFlexBox_spacer4 {
  @include flex-column;
  position: relative;
  flex: 0 0 24px;

  @include md {
    flex: 0 1 10px;
  }

  @include sm {
    flex: 0 1 24px;
  }

  @include xxs {
    flex: 0 1 20px;
  }
}

.contentFlexBox_item5 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}

.userContainer {
  display: flex;
}

.userContainer_layout {
  position: relative;
  height: min-content;
  margin: 11px 0px;

  @include tn {
    margin: 0px
  }
}

.userContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
}

.user_avatar {
  background: var(--src) center center / contain no-repeat;
}

.user_avatar_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;

  @include tn {
    margin: 10px 0px;
  }
}

.userContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 11px;

  @include xs {
    min-width: 8px;
  }

  @include tn {
    min-width: 11px;
  }
}

.userContainer_item1 {
  @include flex-column;
  justify-content: center;
  position: relative;
  flex: 0 0 auto;
}

.user_name {
  align-items: center;
  font: $font_type1;
  color: rgb(117, 89, 58);
  text-align: right;
  letter-spacing: 0px;

  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include lg {
    font-size: 15px;
    text-align: right;
  }

  @include sm {
    font-size: 14px;
  }

  @include xxs {
    font-size: 12px;
  }
}

.user_name_layout {
  position: relative;
  min-height: 20px;
  cursor: pointer;

  @include md {
    display: none;
  }
}

.contentFlexBox_item6 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;

  @include max {
    min-width: unset;
    display: none;
  }

  @include xs {
    display: flex;
  }
}

.dropdown {
  @include flex-column;
}

.dropdown_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 11px 0px;
}

.cover_block {
  @include flex-column;
  background: var(--src) center center / contain no-repeat;
  border: 1px solid rgb(242, 242, 242);
  border-radius: 50px 50px 50px 50px;
}

.cover_block_layout {
  position: relative;
  height: min-content;
  margin: 0px 24px 24px 0px;
}

.icon2 {
  background: var(--src) center center / contain no-repeat;
  border: 1px solid rgb(242, 242, 242);
  border-radius: 50px 50px 50px 50px;
}

.icon2_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}

.loginContain {
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #F1BF70;
  border-radius: 16px;
  height: 80%;
  width: 120px;

  cursor: pointer;
}

.login {
  color: #fff;
  font-weight: 600;
}

.highest {
  z-index: 2000;
}

.popupContainer {
  position: relative;
  width: 90%;
  max-width: 1000px;
  max-height: 600px;
  aspect-ratio: 5/3;

  border-radius: 16px;
  overflow: visible;
}

.popup {
  height: 100%;
  max-height: 600px;
  width: 100%;
  max-width: 1000px;
}

.popupImage {
  height: 100%;
  width: 100%;
  border-radius: 16px;
}

.closeBtnContainer {
  top: 0px;
  right: -53px;
  position: absolute;

  cursor: pointer;

  @include md {
    right: 0px;
  }
}


.subscriptionBtn {
  padding: 10px 15px;
  background: #FCF2E2;
  border-radius: 10px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  transition: background .15s ease-out;

  .vipIcon {
    width: 24px;
    height: 24px;
    transition: background-image .15s ease-out;
    background-image: url('../../assets/header/VIP.svg');
  }

  .subDesc {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    color: #F1BF70;
    white-space: nowrap;
    transition: color .15s ease-out;

    @include sm {
      display: none;
    }
  }

  &:hover {
    background: #F1BF70;

    .vipIcon {
      background-image: url('../../assets/header/VIPHover.svg');
    }

    .subDesc {
      color: #FCF2E2;
      white-space: nowrap;

      @include sm {
        display: none;
      }
    }
  }
}

.contentFlexBox_spacer22 {
  @include flex-column;
  position: relative;
  flex: 0 1 24px;

  @include md {
    flex: 0 1 10px;
  }
}