@import "utils";

$font_type0: 600 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 14px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type2: 500 20px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type3: 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.profileNav {
  position: relative;
}

.curtain {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: transparent;
}

.container {
  position: absolute;
  right: -20px;
  top: 30px;
  width: 227px;
  overflow: auto;
  overscroll-behavior: none;
  @include xs {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
  }
}

.miniContainer {
  position: fixed;
  top: 63px;
  right: calc(5px + (100% - 1200px) / 2); 
  bottom: 30px;
  overflow: hidden;
  @include lg {
    right: 0px;
  }
  @include xs {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
  }
}

.dropDownContainer {
  @include flex-column;
  width: 100%;
  height: 100%;
}
.infoContainer {
  @include flex-column;
  background: var(--src) center center / contain no-repeat;
  @include xs {
    background-color: #f1bf70;
  }
}
.infoContainer_layout {
  position: relative;
  height: min-content;
}
.infoContent {
  @include flex-column;
}
.infoContent_layout {
  position: relative;
  height: min-content;
  margin: 27px 15px 15px;
  width: calc(100% - 30px);
}
.infoFlex {
  max-width: 100%;
  display: grid;
  grid-template-columns: 46px 10px 1fr;
}
.infoFlex_layout {
  position: relative;
  height: min-content;
}
.infoFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 46px;
}
.userIcon {
  background: var(--src) center center / cover no-repeat;

  border-radius: 50%;
}
.userIcon_layout {
  position: relative;
  height: 46px;
  width: 46px;
  min-width: 46px;
}
.infoFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
}
.infoFlex_item1 {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.userInfo {
  max-width: 100%;
}
.userInfo_layout {
  position: relative;
  height: min-content;
  margin: 0px 0px 4px;
}
.userName {
  @include font-face($font_type0, rgb(255, 255, 255), 0px);
}
.userName_layout {
  height: min-content;
  margin: 0px 1.5px;
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.userPoint {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font: $font_type1;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
  font-weight: 500;
}
.userPoint_layout {
  position: relative;
  height: min-content;
  margin: 5px 0px 0px;
}
.naviContainer {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.14901960784313725);
  border-radius: 0 0 16px 16px;
  @include xs {
    flex-grow: 1;
  }
}
.naviContainer_layout {
  position: relative;
  height: min-content;
}

.mininavi {
  overflow: auto;
  overscroll-behavior: none;
}
.accountNav {
  @include flex-column;
  background-color: rgb(253, 248, 240);
}
.accountNav_layout {
  position: relative;
  height: min-content;
}
.accountText {
  @include font-face($font_type2, rgb(240, 190, 111), 0px);
}
.accountText_layout {
  position: relative;
  height: min-content;
  margin: 11px 15px;
}
.accountNavFlex {
  @include flex-column;
}
.accountNavFlex_layout {
  position: relative;
  height: min-content;
  margin: 0px 24px;
}
.basicInfoFlex {
  display: flex;
}
.basicInfoFlex_layout {
  position: relative;
  height: min-content;
}
.basicInfoFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 50px;
}
.basicInfoIcon {
  background: var(--src) center center / contain no-repeat;
}
.basicInfoIcon_layout {
  position: relative;
  height: 50px;
  width: 50px;
  min-width: 50px;
}
.basicInfoFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.basicInfoText {
  @include v-center-content;
  @include font-face($font_type3, rgb(51, 51, 51), 0px);
}
.basicInfoText_layout {
  position: relative;
  flex: 1 1 auto;
  min-height: 20px;
  flex-shrink: 0;
}
.couponFlex {
  display: flex;
}
.couponFlex_layout {
  position: relative;
  height: min-content;
}
.couponFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 50px;
}
.couponIcon {
  background: var(--src) center center / contain no-repeat;
}
.couponIcon_layout {
  position: relative;
  height: 50px;
  width: 50px;
  min-width: 50px;
}
.couponFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.couponText {
  @include v-center-content;
  @include font-face($font_type3, rgb(51, 51, 51), 0px);
}
.couponText_layout {
  position: relative;
  flex: 1 1 auto;
  min-height: 20px;
  flex-shrink: 0;
}
.redeemNavFlex {
  display: flex;
}
.redeemNavFlex_layout {
  position: relative;
  height: min-content;
}
.redeemNavFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 50px;
}
.redeemIcon {
  background: var(--src) center center / contain no-repeat;
}
.redeemIcon_layout {
  position: relative;
  height: 50px;
  width: 50px;
  min-width: 50px;
}
.redeemNavFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.redeemText {
  @include v-center-content;
  @include font-face($font_type3, rgb(51, 51, 51), 0px);
}
.redeemText_layout {
  position: relative;
  flex: 1 1 auto;
  min-height: 20px;
  flex-shrink: 0;
}
.orderNavFlex {
  display: flex;
}
.orderNavFlex_layout {
  position: relative;
  height: min-content;
}
.orderNavFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 50px;
}
.orderIcon {
  background: var(--src) center center / contain no-repeat;
}
.orderIcon_layout {
  position: relative;
  height: 50px;
  width: 50px;
  min-width: 50px;
}
.orderNavFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.orderText {
  @include v-center-content;
  @include font-face($font_type3, rgb(51, 51, 51), 0px);
}
.orderText_layout {
  position: relative;
  flex: 1 1 auto;
  min-height: 20px;
  flex-shrink: 0;
}
.learningNav {
  @include flex-column;
  background-color: rgb(253, 248, 240);
}
.learningNav_layout {
  position: relative;
  height: min-content;
  margin: 1px 0px 0px;
}
.subtitle {
  @include font-face($font_type2, rgb(240, 190, 111), 0px);
}
.subtitle_layout {
  position: relative;
  height: min-content;
  margin: 11px 15px;
}
.learningNavFlex {
  @include flex-column;
}
.learningNavFlex_layout {
  position: relative;
  height: min-content;
  margin: 0px 24px;
}
.historyNavFlex {
  display: flex;
}
.historyNavFlex_layout {
  position: relative;
  height: min-content;
}
.historyNavFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 50px;
}
.historyIcon {
  background: var(--src) center center / contain no-repeat;
}
.historyIcon_layout {
  position: relative;
  height: 50px;
  width: 50px;
  min-width: 50px;
}
.historyNavFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.historyText {
  @include v-center-content;
  @include font-face($font_type3, rgb(51, 51, 51), 0px);
}
.historyText_layout {
  position: relative;
  flex: 0 0 auto;
  min-height: 20px;
  flex-shrink: 0;
}
.favoNavFlex {
  display: flex;
}
.favoNavFlex_layout {
  position: relative;
  height: min-content;
}
.favoNavFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 50px;
}
.favoIcon {
  background: var(--src) center center / contain no-repeat;
}
.favoIcon_layout {
  position: relative;
  height: 50px;
  width: 50px;
  min-width: 50px;
}
.favoNavFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 4px;
}
.favoText {
  @include v-center-content;
  @include font-face($font_type3, rgb(51, 51, 51), 0px);
}
.favoText_layout {
  position: relative;
  flex: 0 0 auto;
  min-height: 20px;
  flex-shrink: 0;
}
.naviContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 1 50px;
}
.logoutBtn {
  @include flex-column;
  background-color: rgb(244, 244, 244);
  border-radius: 28.5px;

  cursor: pointer;
}
.logoutBtn_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 20px auto 23px;
}
.logoutFlex {
  display: flex;
}
.logoutFlex_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 0px 56px;
}
.logoutIcon {
  background: var(--src) center center / contain no-repeat;
}
.logoutIcon_layout {
  position: relative;
  flex: 0 0 auto;
  height: 40px;
  width: 40px;
  min-width: 40px;
}
.logoutFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.logoutText {
  @include v-center-content;
  @include font-face($font_type3, rgb(51, 51, 51), 0px);
}
.logoutText_layout {
  position: relative;
  flex: 0 0 auto;
  min-height: 20px;
  flex-shrink: 0;
}

.icon2 {
  background: var(--src) center center / contain no-repeat;
  border: 1px solid rgb(242, 242, 242);
  border-radius: 50px 50px 50px 50px;
}
.icon2_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
  display: none;
  @include xs {
    display: block;
  }
}

.rotate {
  transform: rotate(-180deg);
}

.spacer {
  @include xs {
    flex-grow: 100;
  }
}

.subscriberTop {
  @include xs {
    top: 68px;
  }
}

.memberTop {
  @include xs {
    top: 96px;
  }
}

.userContainer {
  display: flex;
}
.userContainer_layout {
  position: relative;
  height: min-content;
  margin: 11px 0px;
  @include tn {
    margin: 0px
  }
}
.userContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 24px;
}
.user_avatar {
  background: var(--src) center center / cover no-repeat;

  border-radius: 50%;
}
.user_avatar_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
  @include tn {
    margin: 10px 0px;
  }
}
.userContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 11px;
  @include xs {
    min-width: 8px;
  }
  @include tn {
    min-width: 11px;
  }
}
.userContainer_item1 {
  @include flex-column;
  justify-content: center;
  position: relative;
  flex: 0 0 auto;
}
.user_name {
  @include v-center-content;
  justify-content: flex-end;
  align-items: center;
  font: $font_type1;
  color: rgb(117, 89, 58);
  text-align: right;
  letter-spacing: 0px;
  @include lg {
    font-size: 15px;
    text-align: right;
  }
  @include sm {
    font-size: 14px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.user_name_layout {
  position: relative;
  flex-grow: 1;
  min-height: 20px;
  flex-shrink: 0;
  cursor: pointer;
  @include xs {
    display: none;
  }
}
.contentFlexBox_item6 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include max {
    min-width: unset;
    display: none;
  }
  @include xs {
    display: flex;
  }
}
.dropdown {
  @include flex-column;
}
.dropdown_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 11px 0px;
}

.flexBox {
  display: flex;

  cursor: pointer;
}