@import "utils";

$font_type0: 500 32px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.recommendSection_layout {
  position: relative;
  height: min-content;
  width: 99.83%;
  max-width: 1200px;
  margin: 0px auto;
}
.recommendSection {
  @include flex-column;
}
.contentFlexBox {
  @include flex-column;
}
.contentFlexBox_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  max-width: 1200px;
  margin: 60px 50px;
  @include lg {
    margin: 60px 45px;
  }
  @include md {
    margin: 60px 40px;
  }
  @include sm {
    margin: 60px 25px;
  }
  @include xs {
    margin: 60px 20px;
  }
  @include tn {
    margin: 60px 15px 0px;
  }
}
.titleContainer {
  display: flex;
}
.titleContainer_layout {
  position: relative;
  height: min-content;
  margin: 0px 5px 0px 3px;
  @include lg {
    margin: 0px 5px;
  }
}
.titleContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 181px;
}
.titleFlex {
  display: flex;
}
.titleFlex_layout {
  position: relative;
  flex-grow: 1;
  min-height: 40px;
  flex-shrink: 0;
}
.titleFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 1 32px;
}
.recommendIcon {
  background: var(--src) center center / contain no-repeat;
}
.recommendIcon_layout {
  position: relative;
  height: 36px;
  width: 36px;
  min-width: 36px;
  margin: 6.5px 0px;
  @include lg {
    margin: 6px 0px;
  }
  @include md {
    height: 32px;
    width: 32px;
    min-width: 32px;
    margin: 5px 0px;
  }
  @include xs {
    height: 28px;
    width: 28px;
    min-width: 28px;
  }
}
.titleFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
  @include xs {
    min-width: 8px;
  }
  @include xxs {
    min-width: 4px;
  }
}
.titleFlex_item1 {
  @include flex-column;
  position: relative;
}
.recommendTitle {
  @include v-center-content;
  @include font-face($font_type0, rgb(117, 89, 58), 0px);
  @include web {
    font-size: 24px;
    text-align: left;
  }
  @include xs {
    font-size: 22px;
  }
}
.recommendTitle_layout {
  position: relative;
  flex-grow: 1;
  min-height: 40px;
  flex-shrink: 0;
}
.contentFlexBox_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 23px;
}
.cardsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 15px;
  @include md {
    grid-template-columns: 1fr;
  }
}
.cardsContainer_layout {
  position: relative;
  min-height: 200px;
  flex-shrink: 0;
  margin: 0px 0px 14px;
  @include lg {
    margin: 0px 0px 12px;
  }
  @include md {
    margin: 0px 0px 10px;
  }
  @include sm {
    margin: 0px 23px 9px;
  }
  @include xs {
    margin: 0px 23px 8px;
  }
  @include xxs {
    margin: 0px 23px 7px;
  }
  @include tn {
    margin: 0px 8px 7px;
  }
}
.cardsContainer_item {
  @include flex-column;
  position: relative;
}
.upperFlex {
  display: flex;
  @include md {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.upperFlex_layout {
  position: relative;
  height: min-content;
}
.upperFlex_item {
  @include flex-column;
  position: relative;
  flex: 1 1 560px;
  @include md {
    flex: 0 0 calc(50% - 8px);
  }
  @include sm {
    flex: 0 0 100%;
  }
}
.cardContainer {
  flex-grow: 1;
}
.cardContainer_layout {
  position: relative;
  flex-grow: 1;
  min-height: 100px;
  flex-shrink: 0;
}
.upperFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
  @include md {
    flex: 0 0 16px;
  }
  @include sm {
    display: none;
  }
}
.cardsContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 20px;
  @include sm {
    min-height: 16px;
  }
}
.bottomFlex {
  display: flex;
  @include md {
    flex-wrap: wrap;
    row-gap: 16px;
  }
}
.bottomFlex_layout {
  position: relative;
  height: min-content;
}
.bottomFlex_item {
  @include flex-column;
  position: relative;
  flex: 1 1 560px;
  @include md {
    flex: 0 0 calc(50% - 8px);
  }
  @include sm {
    flex: 0 0 100%;
  }
}
.bottomFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
  @include md {
    flex: 0 0 16px;
  }
  @include sm {
    display: none;
  }
}
