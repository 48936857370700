@import "utils";

$font_type0: 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 14px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.loginContainer {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
  background-color: rgb(242, 242, 242);
  border-radius: 10px;
}
.loginContainer_layout {
  position: relative;
  overflow: hidden;
  min-height: 644px;
  flex-shrink: 0;
  @include xs {
    height: 100%;
  }
}
.loginFlex {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
}
.loginFlex_layout {
  position: relative;
  height: min-content;

  @include xs {
    height: 100%;
  }
}
.closeBtn {
  background: var(--src) center center / contain no-repeat;

  cursor: pointer;
}
.closeBtn_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
  margin: 10px 10px 0px auto;
}
.contentFlex {
  @include flex-column;
}
.contentFlex_layout {
  position: relative;
  height: min-content;
  width: 41.9%;
  margin: 9px auto 29px;

  @include xs {
    margin: auto;
    min-width: 250px;
  }
}
.contentFlex_item {
  @include flex-column;
  position: relative;
}
.mainLogo {
  background: var(--src) center center / contain no-repeat;
}
.mainLogo_layout {
  position: relative;
  height: 177px;
}
.contentFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 49px;
  @include tn {
    min-height: 35px;
  }
}
.emailLogin {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border-radius: 21.5px 21.5px 21.5px 21.5px;

  cursor: pointer;
}
.emailLogin_layout {
  position: relative;
  height: min-content;
}
.emailFlex {
  display: flex;
}
.emailFlex_layout {
  position: relative;
  height: min-content;
  width: 172px;
  min-width: 172px;
  margin: 0px auto;
}
.emailFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.emailIcon {
  background: var(--src) center center / contain no-repeat;
}
.emailIcon_layout {
  position: relative;
  height: 43px;
  width: 44px;
  min-width: 44px;
}
.emailFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 0.5px;
}
.emailText {
  @include v-center-content;
  justify-content: center;
  font: $font_type0;
  color: rgb(118, 89, 58);
  text-align: center;
  letter-spacing: 0px;
  @include xs {
    text-align: center;
  }
  @include tn {
    font-size: 14px;
  }
}
.emailText_layout {
  position: relative;
  flex: 0 0 auto;
  min-height: 20px;
  flex-shrink: 0;
  margin: 11.5px 3.5px 11.5px 0px;
}
.contentFlex_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 36px;
  @include tn {
    min-height: 25px;
  }
}
.googleLogin {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border-radius: 21.5px 21.5px 21.5px 21.5px;
}
.googleLogin_layout {
  position: relative;
  height: min-content;
}
.googleFlex {
  display: flex;
}
.googleFlex_layout {
  position: relative;
  height: min-content;
  width: 165px;
  min-width: 165px;
  margin: 10px auto;
}
.googleFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 1 30.5px;
}
.googleIcon {
  background: var(--src) center center / contain no-repeat;
}
.googleIcon_layout {
  position: relative;
  height: 23px;
  width: 24px;
  min-width: 24px;
  margin: 0px 0px 0px 6.5px;
}
.googleText {
  @include v-center-content;
  justify-content: center;
  font: $font_type0;
  color: rgb(118, 89, 58);
  text-align: center;
  letter-spacing: 0px;
  @include tn {
    font-size: 14px;
    text-align: center;
  }
}
.googleText_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
  margin: 1.5px 0px 1.5px 5.5px;
}
.contentFlex_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 36px;
  @include tn {
    min-height: 25px;
  }
}
.appleLogin {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border-radius: 21.5px 21.5px 21.5px 21.5px;
}
.appleLogin_layout {
  position: relative;
  height: min-content;
}
.appleFlex {
  display: flex;
}
.appleFlex_layout {
  position: relative;
  height: min-content;
  width: 175px;
  min-width: 175px;
  margin: 9px auto;
}
.appleFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 1 35.5px;
}
.appleIcon {
  background: var(--src) center center / contain no-repeat;
}
.appleIcon_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 1px 0px 0px 11.5px;
}
.appleText {
  @include v-center-content;
  justify-content: center;
  font: $font_type0;
  color: rgb(118, 89, 58);
  text-align: center;
  letter-spacing: 0px;
  @include tn {
    font-size: 14px;
    text-align: center;
  }
}
.appleText_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
  margin: 2.5px 0px 2.5px 6.5px;
}
.contentFlex_spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 19px;
  @include tn;
}
.changeTabBtn {
  @include v-center-content;
  justify-content: center;
  font: $font_type0;
  color: rgb(118, 89, 58);
  text-align: center;
  letter-spacing: 0px;
  @include xs {
    font-size: 14px;
    text-align: center;
  }
}
.changeTabBtn_layout {
  position: relative;
  height: min-content;
  margin: 0px 19.5px 0px 18.5px;
}
.contentFlex_spacer4 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 69px;
  @include tn {
    min-height: 40px;
  }
}
.policy {
  @include h-center-content;
  font: $font_type1;
  color: rgb(174, 174, 174);
  text-align: center;
  letter-spacing: 0px;
  @include xs {
    font-size: 12px;
    text-align: center;
  }
  @include tn {
    font-size: 10px;
  }
}
.policy_layout {
  position: relative;
  height: min-content;
  margin: 0px 2px 0px 3px;
}

.registerBtn {
  text-decoration: underline;

  cursor: pointer;
}