@import "utils";

$font_type0: 14px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type4: 16px/0.6 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type2: 11px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.containerStyles {
  position: relative;
  height: calc(100% - 80px);
  max-height: 750px;
  width: calc(100% - 80px);
  max-width: 500px;
  overflow: hidden;

  border-radius: 10px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09803921568627451);
  
  @include sm {
    max-width: unset;
    max-height: unset;
    height: 100%;
    width: 100%;
  }
}

// menu.
.menuContainer {
  width: 500px;
  height: 400px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  overflow: auto;
  overscroll-behavior: none;
  position: absolute;
  right: calc((100% - 1200px) / 2 + 120px);
  top: 100px;
  
  border-radius: 20px;

  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);

  &::-webkit-scrollbar {
    display: none;
  }

  @include lg {
    right: 120px;
  }
  @include sm {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    height: 100%;
  }


  .titleMain {
    padding: 10px 0px;
    font-size: 20px;
    text-align: center;
    background-color: #fff;
    color: #75593a;
    font-weight: 600;

    border-bottom: 1px solid #ededed;
    margin-bottom: 10px;
  }

  // tab 按鈕.
  .tab {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    &.singleTab {
      grid-template-columns: 1fr;
    }

    .item {
      width: 100%;
      
      .title {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 10px;
        border-radius: 20px;
        text-align: center;
        cursor: pointer;
        width: 100%;
        max-width: 160px;
        margin: 0 auto;

        .mr15 {
          margin-right: 15px;
        }

        &.active {
          color: #fff;
          background-color: #f1bf70;
          font-weight: 600;
        }
        .redOuter {
          position: relative;
          .unreadCount {
            position: relative;
            background-color: #ff5d5d;
            padding: 5px;
            line-height: 1rem;
            border-radius: 20px;
          }
          .redBgContainer {
           content: '';
           position: absolute;
           top: calc(50% - 15px);
           left: calc(50% - 15px);
           width: 30px;
           height: 30px;
           border-radius: 50%;
           background-color: #FF5D5D;
         }
        }
      }
    }
  }

  // 消息列表.
  .list {
    .toggleContainer {
      width: 100%;
      height: 100%;
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

// 編輯器容器.
.quillContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 24px;
}


.notifications {
  @include flex-column;
  background: var(--src) center center / contain no-repeat;

  cursor: pointer;
}
.notifications_layout {
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
}
.redBg {
  background: var(--src) center center / contain no-repeat;
  border: 1px solid rgb(242, 242, 242);
  border-radius: 50px 50px 50px 50px;
}
.redBg_layout {
  position: absolute;
  top: 3px;
  height: 20px;
  width: 20px;
  right: 10px;
}
.notiCount {
  @include flex-column;
  border-width: 0px;
  background-color: #FF5D5D;
  border-radius: 10px;
}
.notiCount_layout {
  position: absolute;
  top: 3px;
  height: 20px;
  left: 55%;
  padding: 0px 5px;
}
.notiCountText {
  @include v-center-content;
  justify-content: center;
  font: $font_type2;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
}
.notiCountText_layout {
  position: relative;
  flex-grow: 1;
  min-height: 20px;
  flex-shrink: 0;
}

.zIndex {
  z-index: 2000;
}

.closeBtnContainer {
  display: none;

  cursor: pointer;
  @include sm {
    display: block;
    position: absolute;
    top: 0;
    right: 10px;
  }
  
}

.closeBtn {
  width: 44px;
  height: 44px;
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.titleContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 5px;
  padding-left: 20px;

  border-bottom: 1px solid #f2f2f2;
  padding: 20px 5px 20px 20px;
}

.announceModalTitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 25px;
  font-weight: 600;
  text-align: left;
  color: #75593a;
}

.announceModalClose {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}