@import "utils";

$font_type0: 16px/1.5 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 14px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.chapterContainer {
  display: flex;
  flex-grow: 1;
  padding: 10px 15px 10px 5px;
}

.chapterContainer_layout {
  position: relative;
  height: min-content;
}

.chapterContainer_item {
  @include flex-column;
  justify-content: center;
  position: relative;
  flex: 0 0 auto;
}

.chapterStatusIcon {
  @include flex-column;

  cursor: pointer;
}

.chapterStatusIcon_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;

  @include xxs {
    height: 30px;
    width: 30px;
    min-width: 30px;
  }
}

.playing {
  background: var(--src) center center / contain no-repeat;
  border-radius: 2px 2px 2px 2px;
}

.playing_layout {
  position: absolute;
  top: 0px;
  height: 44px;
  left: 0px;
  width: 44px;
  min-width: 44px;
  margin: 0px 0px 0px 5px;

  @include xxs {
    height: 30px;
    width: 30px;
    min-width: 30px;
  }
}

.available {
  background: var(--src) center center / contain no-repeat;
  border-radius: 2px 2px 2px 2px;
}

.available_layout {
  position: absolute;
  top: 0px;
  height: 44px;
  left: 0px;
  width: 44px;
  min-width: 44px;
  margin: 0px 0px 0px 5px;

  @include xxs {
    height: 30px;
    width: 30px;
    min-width: 30px;
  }
}

.locked {
  background: var(--src) center center / contain no-repeat;
}

.locked_layout {
  position: absolute;
  top: 0px;
  height: 44px;
  left: 0px;
  width: 44px;
  min-width: 44px;
  margin: 0px 0px 0px 5px;

  @include xxs {
    height: 30px;
    width: 30px;
    min-width: 30px;
  }
}

.chapterContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
}

.chapterContainer_item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}

.chapterInfo {
  display: flex;

  @include max {
    flex-wrap: wrap;
    row-gap: 10px;
  }
}

.chapterInfo_layout {
  position: relative;
  height: min-content;
  margin: 1px 0px 0px;
}

.chapterInfo_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;

  @include max {
    flex: 0 0 100%;
  }
}

.chapterTitle {
  display: -webkit-box;
  @include font-face($font_type0, rgb(51, 51, 51), 0px);

  cursor: pointer;
}

.chapterTitle_box {
  @include v-center-content;
}

.chapterTitle_box_layout {
  position: relative;
  height: min-content;
}

.chapterInfo_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;

  @include max {
    display: none;
  }
}

.infoContainer {
  display: flex;
}

.infoContainer_layout {
  position: relative;
  height: min-content;
}

.infoContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}

.timeInfo {
  @include v-center-content;
  @include font-face($font_type1, rgb(174, 174, 174), 0px);
}

.timeInfo_layout {
  position: relative;
  flex-grow: 1;
  min-height: 17px;
  flex-shrink: 0;
}

.infoContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 6px;
  @include tn;
}

.block3 {
  @include flex-column;
  background: var(--src) center center / 100% 100% no-repeat;
}

.block3_layout {
  position: relative;
  flex-grow: 1;
  width: 4px;
  min-width: 4px;
  margin: 4px 0px;
}

.infoContainer_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 6px;
  @include tn;
}

.viewedContainer {
  display: flex;
}

.viewedContainer_layout {
  position: relative;
  height: min-content;
}

.viewedContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 1 16px;
}

.viewedIcon {
  background: var(--src) center center / contain no-repeat;
}

.viewedIcon_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}

.viewedContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 4px;
}

.viewedText {
  @include v-center-content;
  @include font-face($font_type1, rgb(174, 174, 174), 0px);
}

.viewedText_layout {
  position: relative;
  flex: 0 0 auto;
  min-height: 17px;
  flex-shrink: 0;
}

.chapterContainer_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 15px;

  @include xxs {
    min-width: 14px;
  }

  @include tn {
    min-width: 10px;
  }
}

.favIcon {
  background: var(--src) center center / contain no-repeat;
}

.favIcon_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;

  @include xxs {
    height: 30px;
    width: 30px;
    min-width: 30px;
  }
}

.manuscriptIcon {
  background: var(--src) center center / contain no-repeat;
}

.manuscriptIcon_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;

  @include xxs {
    height: 30px;
    width: 30px;
    min-width: 30px;
  }
}

.flexOne {
  display: flex;
  flex-grow: 1;
}

.bolder {
  font-weight: 600;
}

.learnedBg {
  background-color: #f6f6f6;
}

.playingBg {
  background-color: #FCF2E2;
}

.favContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.favIconRWD {
  background: var(--src) center center / contain no-repeat;

  cursor: pointer;
}

.favIconRWD_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;

  @include xxs {
    height: 32px;
    width: 32px;
    min-width: 32px;
  }
}

.spacer {
  width: 10px;
}