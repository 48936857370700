@import "utils";

.zTen {
  z-index: 1000;
}

.modalContainer {
  width: 90%;

  border-radius: 16px;
}

.modalHeader {
  padding: 10px 20px;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #75593a;
  font-size: 18px;
  font-family: 'Noto Sans TC', sans-serif;
  font-weight: 400;
}

.selectContainer {
  padding: 10px 20px;
  
  .select {
    padding: 10px 0;
    border-bottom: 1px solid #f2f2f2;
    text-align: center;
    font-family: 'Noto Sans TC', sans-serif;
    &:last-child {
      border-bottom: none;
    }

    &.active {
      color: #f1bf70;
    }
  }
}

.containerStyles {
  position: absolute;
  width: 200%;
  max-width: 100px;


  bottom: calc(100% + 20px);
  left: -50%;

  border: 1px solid #aaa;
  border-radius: 16px;
  overflow: hidden;

  z-index: 1001;
  
  &.inMenu {
    left: unset;
    right: 120%;
    bottom: 20px;
  }

  &.isLand {
    right: 110%;
    width: 100px;
    bottom: 0;
  }
}

.shadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1000;

  background: transparent;
}

.tag {
  text-align: center;
  padding: 8px;
  border-bottom: 1px solid #aaa;
  color: #75593a;
  background-color: #fff;
  cursor: pointer;
  font-family: 'Noto Sans TC', sans-serif;
  &:last-child {
    border-bottom: none;
  }

  &.active {
    color: #f1bf70;
  }
  &.isLand {
    padding: 4px 8px;
  }
}

