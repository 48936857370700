@import "utils";

$font_type0: 500 18px/1.5 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 16px "Noto Sans TC", Helvetica, Arial, serif;
$font_type2: 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type4: 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.learnedCard {
  @include flex-column;
}
.learnedCard_layout {
  position: relative;
  height: 100%;

  overflow: hidden
}
.upperContainer {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border-radius: 12px 12px 12px 12px;
}
.upperContainer_layout {
  position: relative;
  overflow: hidden;
  height: min-content;
}
// NOTE: video thumbnail

.vThumbnailContainer {
  @include flex-column;
  border-radius: 12px 0px 0px 12px;
}
.vThumbnailContainer_layout {
  position: relative;
  overflow: hidden;
  height: min-content;
  width: 100%;
  padding-top: 75%;
}

.vThumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: top;
  object-fit: cover;
  object-position: center top;
}

// NOTE: audio thumbnail

.disk {
  border-radius: 12px 12px 12px 12px;
  vertical-align: top;
  background: var(--src) center center / contain no-repeat;
}
.disk_layout {
  position: absolute;
  top: 5%;
  right: 8px;
  height: 90%;
  width: 75%;
}
.aThumbnailContainer {
  @include flex-column;
  border-radius: 12px 0px 0px 12px;
}
.aThumbnailContainer_layout {
  position: relative;
  overflow: hidden;
  height: min-content;
  width: 100%;
  padding-top: 75%;
  margin: 0px 25% 0px 0%;
}
.aThumbnail {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px 0px 0px 12px;
  width: 75%;
  height: 100%;
  // height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center center;
}

// NOTE: audio book thumbnail
.abThumbnailContainer {
  @include flex-column;
  border-radius: 7px 7px 0px 0px;
}
.abThumbnailContainer_layout {
  position: relative;
  overflow: hidden;
  height: min-content;
  width: 100%;
  padding-top: 75%;
  background-color: #fff;
  margin: 0px auto;
}
.abThumbnail {
  position: absolute;
  top: 0;
  left: 12.5%;
  border-radius: 7px 7px 0px 0px;
  width: 75%;
  height: 100%;
  vertical-align: top;
  object-fit: cover;
  object-position: center top;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
}
.favIcon {
  background: var(--src) center center / contain no-repeat;
}
.favIcon_layout {
  position: absolute;
  z-index: 0;
  top: 10px;
  height: 24px;
  width: 24px;
  right: 10px;
}
.infoContainer {
  @include flex-column;
}
.infoContainer_layout {
  position: relative;
  flex-grow: 1;
  margin: 13px 0px 5px 15px;
  @include lg {
    margin: 11px 0px 5px 13px;
  }
  @include md {
    margin: 10px 0px 5px 11px;
  }
  @include sm {
    margin: 8px 0px 5px 9px;
  }
  @include xs {
    margin: 7px 0px 5px 9px;
  }
  @include xxs {
    margin: 7px 0px 5px 8px;
  }
  @include tn {
    margin: 6px 0px 5px 7px;
  }
}
.contentFlex {
  @include flex-column;
}
.contentFlex_layout {
  position: relative;
  flex-grow: 1;
}
.titleContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  flex-grow: 1;
}
.titleContainer_layout {
  position: relative;
}
.title {
  display: -webkit-box;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type0, rgb(51, 51, 51), 0px);
  white-space: pre-wrap;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @include web {
    font-size: 16px;
    text-align: left;
  }
}
.title_box {
  @include lg {
    align-items: center;
    justify-content: flex-start;
  }
  @include md {
    align-items: center;
    justify-content: flex-start;
  }
  @include sm {
    align-items: center;
    justify-content: flex-start;
  }
  @include xxs {
    align-items: center;
    justify-content: flex-start;
  }
}
.typeIcon {
  background: var(--src) center center / contain no-repeat;
}
.typeIcon_layout {
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin-top: 5px;
  @include sm {
    width: 18px;
    min-width: 18px;
  }
  @include xxs {
    width: 16px;
    min-width: 16px;
  }
}
.otherInfoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.otherInfoContainer_layout {
  position: relative;
  height: min-content;
  margin: 14px 0px 0px 2px;
  @include lg {
    margin: 12px 0px 0px 5px;
  }
  @include md {
    margin: 10px 0px 0px 5px;
  }
  @include sm {
    margin: 9px 0px 0px 5px;
  }
  @include xs {
    margin: 8px 0px 0px 5px;
  }
  @include xxs {
    margin: 7px 0px 0px 5px;
  }
}
.authorName {
  @include font-face($font_type1, rgb(174, 174, 174), 0px);
  @include lg {
    font-size: 15px;
    text-align: left;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.authorName_layout {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: min-content;
  padding-left: 25px;
  @include sm {
    padding-left: 23px;
  }
  @include xxs {
    padding-left: 21px;
  }
}
.otherInfoContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 1 10 206px;
}
.progress {
  display: flex;
  justify-content: flex-end;
  font: $font_type2;
  color: rgb(117, 89, 58);
  text-align: right;
  letter-spacing: 0px;
  @include lg {
    font-size: 15px;
    text-align: right;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.progress_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
}
.leftBar {
  background-color: rgb(117, 89, 58);
  border-radius: 1.5px 1.5px 1.5px 1.5px;
}
.leftBar_layout {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -12px;
  width: 3px;
  @include lg {
    height: 79px;
  }
  @include sm {
    left: -9px;
  }
}


.popularityContainer {
  display: flex;
  justify-content: flex-end;
}
.popularityContainer_layout {
  position: relative;
  height: min-content;
}
.popularityContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
}
.viewIcon {
  background: var(--src) center center / contain no-repeat;
}
.viewIcon_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.popularityContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 3px;
}
.popularity {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type4;
  color: rgb(117, 89, 58);
  text-align: right;
  letter-spacing: 0px;
}
.popularity_layout {
  position: relative;
  flex: 0 0 auto;
  min-height: 20px;
  flex-shrink: 0;
}

.otherLeft {
  flex: 0 1 auto;
  overflow: hidden;
  
}

.otherRight {
  flex: 1 0 auto;
  margin-right: 5px;
}

.notRating {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 14px;
  line-height: 1.2rem;
  color: #fff;
  background-color: rgb(128, 128, 128);
  padding: 2px;
  border: 1px solid rgb(169, 169, 169);
  border-radius: 2px;
}