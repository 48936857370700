.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before { content: attr(data-value) !important; }

.ql-editor {
  padding: 0px !important;
  font-family: 'Noto sans TC', sans-serif;
  line-height: 1.5 !important;
  color: #333;
}

.ql-videoContainer {
  width: 100%;
  aspect-ratio: 16/9;
}

.content {
  position: relative;
  width: 100% !important;
  padding-top: 56.25% !important;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 576px) {
  .ql-videoContainer {
    /* pointer-events: none; */
  }

  .blocker {
    /* position: absolute; */
    /* display: block; */
    /* width: 100%; */
    /* padding-top: 100% !important; */
    background-color: rgba(0, 0, 0, 0.2);
  }
}