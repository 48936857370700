@import "utils";

$font_type0: 600 24px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.subscriptionSection {
  position: relative;
  overflow: visible;
  display: block;
  cursor: pointer;
  margin-bottom: 60px;
  width: 100%;
  background-image: url('../../../../assets/homepage/subscription/ad.png');
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 1200 / 355;
  @include sm {
    margin-bottom: 50px;
    background-image: url('../../../../assets/homepage/subscription/mobileAd.png');
    aspect-ratio: 375 / 500;
  }
  
}

.joinBtn {
  @include flex-column;
  background-color: rgb(241, 191, 112);
  border-radius: 28.5px 28.5px 28.5px 28.5px;

  cursor: pointer;
}
.joinBtn_layout {
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 50%);
  height: min-content;
  width: 99.52%;
  max-width: 413px;
  margin: 0px auto;
  @include sm {
    width: 41.34%;
  }
  @include xs {
    width: 45.04%;
  }
}
.medium_title {
  @include v-center-content;
  justify-content: center;
  font: $font_type0;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
  @include md {
    font-size: 20px;
    text-align: center;
  }
  @include xxs {
    font-size: 18px;
  }
  @include tn {
    font-size: 16px;
  }
}
.medium_title_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 16px auto;
  @include lg {
    margin: 14px auto;
  }
  @include md {
    margin: 12px auto;
  }
  @include sm {
    margin: 16px auto;
  }
  @include xxs {
    margin: 14px auto;
  }
  @include tn {
    margin: 12px auto;
  }
}
