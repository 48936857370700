.container {
  .button {
    width: 360px;
    height: 40px;
    background-color: #1890ff;

    font-size: 16px;
    color: #ffffff;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &.disabled {
      cursor: auto;
      background-color: #eee;
    }
  }

  .resMessage {
    color: #ff4d4f;
  }
}
