.screenControls {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 10001;
  position: absolute;
  top: 0;
  left: 0;

  cursor: pointer;
  
  &.isLandscape {
    height: 100vh;
  }

  .controlsContainer {
    width: 100%;

    transform: translateY(100%);
    transition: transform 0.3s ease;

    position: absolute;
    left: 0px;
    bottom: 0px;

    &.hover {
      transform: translateY(0%);
    }

    &.hide {
      display: none;
    }
  }

  .curtain {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    &.show {
      display: block;
    }
  }

  .screenfullButtonContainer {
    font-size: 16px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
}


.fullscreenIcon {
  background: var(--src) center center / cover no-repeat;

  cursor: pointer;
}
.fullscreenIcon_layout {
  position: absolute;
  height: 40px;
  width: 40px;

  bottom: 10px;
  right: 10px;
}