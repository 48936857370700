@import "utils";

.zIndex {
  z-index: 1005;
}

.container {
  width: calc(100% - 48px);
  height: 100%;
  max-width: 375px;
  max-height: 268px;
  border-radius: 20px;

  background-color: #fff;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;

  overflow: hidden;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
  .headerText {
    color: #75593a;
    font-weight: 500;
    font-size: 20px;
  }
}

.contentContainer {
  background-color: #F4F4F4;
  display: flex;
  align-items: center;
  justify-content: center;
  .contentText {
    white-space: pre-wrap;
    text-align: center;
    font-size: 16px;
    line-height: 1.5rem;
    color: #333333;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .button {
    width: 65%;
    border-radius: 20px;
    background-color: #f1bf70;

    color: #fff;
    font-weight: 600;
    margin: 12px;
    padding: 10px 0px;
    text-align: center;
    font-size: 16px;
  }
}