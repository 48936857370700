.quillContainer {
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
} 
/* NOTE: for edit */
/* .quillContainer iframe,
.quillContainer video {
    pointer-events: none;
} */
