/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import "utils";

$font_type0: 500 24px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type2: 600 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.emailVerifyPage {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border-radius: 10px 10px 10px 10px;

  @include xs {
    height: 100%;
  }
}
.emailVerifyPage_layout {
  position: relative;
  overflow: hidden;
  min-height: 644px;
  flex-shrink: 0;
}
.contentFlex {
  @include flex-column;
}
.contentFlex_layout1 {
  position: relative;
  height: min-content;
  margin: 10px;

  @include xs {
    height: 100%;
  }
}
.closeBtn {
  background: var(--src) center center / contain no-repeat;
}
.closeBtn_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
}
.contentFlex_layout {
  position: relative;
  height: min-content;
  width: 54.2%;
  margin: 107px auto 152px;

  @include xs {
    margin: auto;
  }
}
.successIcon {
  background: var(--src) center center / contain no-repeat;
}
.successIcon_layout {
  position: relative;
  height: 60px;
  width: 60px;
  min-width: 60px;
  margin: 0px auto;
}
.contentFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 25px;
}
.verifyTitle {
  @include h-center-content;
  font: $font_type0;
  color: rgb(51, 51, 51);
  text-align: center;
  letter-spacing: 0px;
}
.verifyTitle_layout {
  position: relative;
  height: min-content;
}
.contentFlex_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 56.5px;
}
.contentFlex_item {
  @include flex-column;
  position: relative;
}

.contentFlex_item2{
  @include flex-column;
  position: relative;
  align-items: center;
}
.verifyDescription {
  @include v-center-content;
  justify-content: center;
  font: $font_type1;
  color: rgb(51, 51, 51);
  text-align: center;
  letter-spacing: 0px;
}
.verifyDescription_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 5px 0;
}
.contentFlex_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 67.5px;
}
.resendBtn {
  @include flex-column;
  background-color: #D6D6D6;
  border-radius: 22px 22px 22px 22px;
}
.resendBtn_layout {
  position: relative;
  height: min-content;
  margin: 0px 12px 0px 15px;
}
.resendBtnActive {
  background-color: rgb(241, 191, 112);
}

.resendText {
  @include h-center-content;
  font: $font_type2;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
}
.resendText_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 12px auto;
}

.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}