// 時間軸.
.inputRange {
  height: 6px;
}

.selfTimeTrack {
  background-color: transparent;
  height: 6px;

  background-color: #D8D8D7;

  &::after {
    display: block;
    content: '';
    width: 100%;
    height: 20px;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    z-index: 1;
  }
}

.selfTimeSlider {
  background: url('../../../../../../assets/player/dot.png') center center / contain no-repeat;
  border: none;

  &::after {
    display: block;
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 100%;
    transform: translate(-50%, calc(-50% - 4px));
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
  }
}

.selfTimeActiveTrack {
  background: transparent;
}

.selfTimeActiveTrack::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;

  width: calc(100% - 6px);
  background-color: #f1bf70;
  height: 6px;
  border-radius: 4px 0px 0px 4px;

}
