.banner .swiper {
  padding-top: 15px;
  padding-bottom: 40px;

  width: 100%;
  max-width: 1725px;
}

.banner .bullet {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 4px;
  background: #bfbfbf;
  border-radius: 50%;
  opacity: .8;
  cursor: pointer;
}

.banner .swiper-wrapper {
  overflow: visible;
}

.banner .active-bullet{
  position: relative;;
  width: 6px;
  height: 6px;
  background: rgb(241, 191, 112);
  opacity: 1;
}

.banner .swiper-slide {
  width: 100%;
  max-width: 700px;
  aspect-ratio: 16/9;
  border-radius: 15px;

  opacity: 0;
}

.banner .swiper-slide-visible {
  opacity: 1;
}

.banner .active-bullet::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;

  /* NOTE: remember add border width */
  transform: translate(-5px, -5px);

  width: 10px;
  height: 10px;
  border: 1px solid rgb(241, 191, 112);
  border-radius: 50%;
}

.banner .swiper-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left,rgba(255, 255, 255, 0),rgba(255, 255, 255, 0));
}

.banner .swiper-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right,rgba(255, 255, 255, 0),rgba(255, 255, 255, 0));
}

@media screen and (min-width: 768px) {
  .banner .swiper-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left,rgba(255, 255, 255, 0.7),rgba(255, 255, 255, 0.7));
  }
  
  .banner .swiper-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right,rgba(255, 255, 255, 0.7),rgba(255, 255, 255, 0.7));
  }
}
