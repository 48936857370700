@import "utils";

$font_type0: 600 14px/1.3 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 14px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type2: 600 12px "Noto Sans TC", Helvetica, Arial, serif;
$font_type3: 500 12px "Noto Sans TC", Helvetica, Arial, serif;

.littlePlayer {
  @include flex-column;
  background-color: rgba(172, 155, 137, 0.901);
}

.littlePlayer_layout {
  position: relative;
  z-index: 1;
  border-radius: 15px 15px 0px 0px;

  @include md {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  @include sm {
    margin: 0px;
  }

  &.isFullScreen {
    position: relative;
    bottom: unset;
  }
}

.playerFlex {
  display: flex;
  align-items: center;
  justify-content: center;

  @include sm {
    flex-wrap: wrap;
    padding: 5px 8px 5px 0px;
  }
}

.playerFlex_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: 100%;
  margin: 1px;

  @include md {
    height: 98px;
  }

  @include sm {
    display: grid;
    grid-template-columns: auto 128px 1fr auto;
  }

  @include xxs {
    grid-template-columns: auto 70px 1fr auto;
    grid-gap: 5px;
  }
}

.playerFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 41px;

  @include max {
    display: none;
  }

  @include lg {
    flex: 1 1 30px;
    display: flex;
  }

  @include md {
    display: none;
  }

  @include sm {
    flex: 0 0 25%;
    order: 4;
  }

  @include xs {
    flex: 0 0 20%;
  }
}

.playerFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;

  @include max {
    display: none;
  }

  @include sm {
    display: flex;
    flex: 0 0 20%;
    display: none;
  }

  @include xxs {
    flex: 0 0 16.666666666666664%;
  }
}

.favIconRWD {
  background: var(--src) center center / contain no-repeat;
}

.favIconRWD_layout {
  position: relative;
  height: 24px;
  width: 24px;
  min-width: 24px;

  @include sm {
    margin: 0px 0px 0px 20px;
  }

  @include xxs {
    margin: 0px 0px 0px 15px;
  }
}

.playerFlex_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;

  @include sm {
    width: 32px;
    height: 32px;
    grid-column: 1/2;
  }

  cursor: pointer;
}

.closeIcon {
  background: var(--src) center center / contain no-repeat;
}

.closeIcon_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
  margin: 30px 0px;

  @include sm {
    margin: 0px;
    width: 32px;
    height: 32px;
    min-width: 32px;
  }
}

.playerFlex_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 320px;

  @include sm {
    grid-column: 2/3;
    margin: 0px 24px;
  }

  @include xxs {
    margin: 0px;
  }
}

.courseInfoContainer {
  display: flex;
}

.courseInfoContainer_layout {
  position: relative;
  height: min-content;
  margin: 1px 0px;
}

.courseInfoContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 0 94px;
  border-radius: 10px;
  overflow: hidden;

  @include md {
    flex: 0 0 72px;
  }

  @include sm {
    width: 70px;
    height: 72px;
  }
}

.courseThumbnail {
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}

.courseThumbnail_layout {
  position: relative;
  width: 95px;
  min-width: 95px;
  margin: 5px 0px;
}

.courseInfoContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 10px;

  @include sm {
    display: none;
  }
}

.courseInfoContainer_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 216px;

  @include sm {
    display: none;
  }
}

.couseDetail {
  @include flex-column;
  justify-content: center;
}

.couseDetail_layout {
  position: relative;
  flex-grow: 1;
  min-height: 70px;
  flex-shrink: 0;
  margin: 1px 0px;
}

.couseDetail_item {
  @include flex-column;
  position: relative;
}



.courseTitle {
  display: -webkit-box;
  overflow: hidden;
  height: min-content;
  @include font-face($font_type0, rgb(255, 255, 255), 0px);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @include sm {
    -webkit-line-clamp: 1;
    margin-bottom: 5px;
  }
}

.courseTitle_box {
  @include v-center-content;

  @include sm {
    -webkit-line-clamp: 1;
  }
}

.smallCourseTitle {
  display: none;

  @include sm {
    display: -webkit-box;
  }
}

.courseTitle_box_layout {
  position: relative;
  height: min-content;
}

.couseDetail_item1 {
  @include flex-column;
  position: relative;

  @include sm {
    flex: 0 1 auto;
  }
}

.otherInfoContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
}

.otherInfoContainer_layout {
  position: relative;
  height: min-content;
}

.otherInfoContainer_item {
  display: flex;
  position: relative;
  flex: 1 0 auto;
  padding: 5px 0;

  overflow: hidden;

  @include sm {
    flex: 1 0 auto;
    min-width: unset;
  }
}

.author {
  @include font-face($font_type1, rgb(255, 255, 255), 0px);
}

.author_layout {
  display: -webkit-box;
  position: relative;
  width: 100%;
  min-height: 17px;
  margin: 0px 0px 1px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  overflow: hidden;

  @include tn {
    margin: 0px 0px 10px;
  }

  @include sm {
    -webkit-line-clamp: 1;
  }
}

.otherInfoContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 3 138px;

  @include sm {
    flex: 0 3 138px;
    display: none;
  }
}

.otherInfoContainer_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;


  cursor: pointer;

  @include sm {
    display: none;
  }
}

.favIcon {
  background: var(--src) center center / contain no-repeat;

}

.favIcon_layout {
  position: relative;
  width: 24px;
  height: 24px;
  min-width: 24px;
  margin: 1px 0px 0px;
}

.playerFlex_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;

  @include sm {
    display: none;
  }
}

.playerFlex_item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 419px;

  @include sm {
    grid-column: 3/4;
    margin-right: 24px;
  }

  @include xxs {
    margin-right: 0px;
    margin-left: 5px;
  }
}

.controlContainer {
  @include flex-column;
}

.controlContainer_layout {
  position: relative;
  height: min-content;
  margin: 15px 0px 0px;

  @include sm {
    margin: 0px;
  }
}

.controlContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}

.progressBarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressBarContainer_layout {
  position: relative;
  height: min-content;

  @include sm {
    height: 12px;
  }
}

.nowTime {
  display: flex;
  justify-content: flex-end;
  font: $font_type2;
  color: rgb(255, 255, 255);
  text-align: right;
  letter-spacing: 0px;
}

.nowTime_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;
  padding-right: 5px;

  @include xxs {
    display: none;
  }
}

.progressBarContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 10px;
}

.progressBarContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 1 500px;
}

.progressBar {
  display: flex;
  // background-color: rgba(117, 89, 58, 0.25);
  border-radius: 3px 3px 3px 3px;
}

.progressBar_layout {
  position: relative;
  height: min-content;
}

.progressBar_item {
  @include flex-column;
  position: relative;
  flex: 0 1 91px;
}

.played {
  background-color: rgb(241, 191, 112);
  border-radius: 3px 3px 3px 3px;
}

.played_layout {
  position: relative;
  height: 6px;
  margin: 2px 0px;
}

.progressBar_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 236px;
}

.timeDot {
  background: var(--src) center center / contain no-repeat;
}

.timeDot_layout {
  position: relative;
  height: 10px;
  width: 10px;
  min-width: 10px;
  margin: 0px 226px 0px 0px;

  @include lg {
    margin: 0px 179px 0px 0px;
  }
}

.totalTime {
  @include font-face($font_type2, rgb(255, 255, 255), 0px);
}

.totalTime_layout {
  position: relative;
  flex: 0 0 auto;
  height: min-content;

  @include sm {
    padding-left: 5px;
  }

  @include xxs {
    display: none;
  }
}

.controlContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 10px;

  @include md {
    display: none;
  }
}

.controlContainer_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;

  @include sm {
    margin-top: 5px;
  }
}

.controlBtnsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.controlBtnsContainer_layout {
  position: relative;
  height: min-content;
  width: 77.14%;
  margin: 0px auto;

  @include sm {
    width: 100%;
    margin: 0px 0px;
  }
}

.controlBtnsContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 1 23px;

  @include sm {
    display: none;
  }
}

.back15Btn {
  background: var(--src) center center / contain no-repeat;

  cursor: pointer;
}

.back15Btn_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
  margin: 14px 0px;
}

.controlBtnsContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 40px;

  @include sm {
    display: none;
  }
}

.controlBtnsContainer_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 20px;
}

.prevBtn {
  background: var(--src) center center / contain no-repeat;

  cursor: pointer;
}

.prevBtn_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
  margin: 14px 0px;

  @include sm {
    height: 32px;
    width: 32px;
    min-width: 32px;
    margin: 0px;
  }
}

.controlBtnsContainer_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 47px;

  @include sm {
    display: none;
  }
}

.controlBtnsContainer_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 52px;
}

.mainContorl {
  background: var(--src) center center / contain no-repeat;

  cursor: pointer;
}

.mainContorl_layout {
  position: relative;
  height: 72px;
  width: 72px;
  min-width: 72px;

  @include sm {
    height: 48px;
    width: 48px;
    min-width: 48px;
    margin: 0px 32px;
  }

  @include xxs {
    margin: 0px 20px;
  }
}

.controlBtnsContainer_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 48px;

  @include sm {
    display: none;
  }
}

.controlBtnsContainer_item3 {
  @include flex-column;
  position: relative;
  flex: 0 1 21px;

}

.nextBtn {
  background: var(--src) center center / contain no-repeat;
  transform: rotate(180deg);

  cursor: pointer;
}

.nextBtn_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
  margin: 14px 0px;

  @include sm {
    height: 32px;
    width: 32px;
    min-width: 32px;
    margin: 0px;
  }
}

.controlBtnsContainer_spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 50px;

  @include sm {
    display: none;
  }
}

.add15Btn {
  background: var(--src) center center / contain no-repeat;

  cursor: pointer;
}

.add15Btn_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
  margin: 14px 0px;
}

.playerFlex_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 35px;

  @include lg {
    flex: 0 1 25px;
  }

  @include sm {
    display: none;
    flex: 0 0 20px;
    order: 9;
  }
}

.playerFlex_item4 {
  @include flex-column;
  position: relative;
  flex: 0 1 51px;

  @include sm {
    display: none;
    flex: 0 0 20%;
    order: 10;
  }

  @include xxs {
    flex: 0 0 16.666666666666664%;
  }

  z-index: 1;
}

.speedContainer {
  @include flex-column;

  cursor: pointer;
}

.speedContainer_layout {
  position: relative;
  height: min-content;
  margin: 23px 0px;
}

.speedImage {
  background: var(--src) center center / contain no-repeat;

  cursor: pointer;
}

.speedImage_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
  margin: 0px 11.5px;
}

.speedText {
  @include h-center-content;
  font: $font_type3;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
}

.speedText_layout {
  position: relative;
  width: 100%;
  margin: 5px 0px 0px;
}

.playerFlex_spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 15px;

  @include sm {
    display: none;
    flex: 0 0 20px;
    order: 11;
  }
}

.playerFlex_item5 {
  @include flex-column;
  cursor: pointer;
  position: relative;
  flex: 0 1 50px;

  @include sm {
    display: none;
    flex: 0 0 20%;
    order: 12;
  }

  @include xxs {
    flex: 0 0 16.666666666666664%;
  }
}

.manuscriptBtn {
  @include flex-column;
  cursor: pointer;
}

.manuscriptBtn_layout {
  position: relative;
  height: min-content;
  margin: 12px 0px;
}

.activeManuscript {
  background-color: rgb(241, 191, 112);
  border-radius: 10px 10px 10px 10px;
}

.containerFlex {
  @include flex-column;
}

.containerFlex_layout {
  position: relative;
  height: min-content;
  margin: 11px 0px;
}

.manuscriptIcon {
  background: var(--src) center center / contain no-repeat;
}

.manuscriptIcon_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
  margin: 0px 3px;
}

.manuscriptText {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  font: $font_type3;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
  white-space: pre-wrap;
}

.manuscriptText_box {
  @include h-center-content;
}

.manuscriptText_box_layout {
  position: relative;
  height: min-content;
  margin: 5px 0px 0px;
}

.playerFlex_spacer4 {
  @include flex-column;
  position: relative;
  flex: 0 1 15px;

  @include sm {
    display: none;
    flex: 0 0 20px;
    order: 13;
  }
}

.playerFlex_item6 {
  @include flex-column;
  position: relative;
  flex: 0 1 121px;

  @include lg {
    display: none;
  }

  @include sm {
    flex: 0 0 20%;
    order: 14;
  }

  @include xxs {
    flex: 0 0 16.666666666666664%;
  }
}

.volumeContainer {
  display: flex;
}

.volumeContainer_layout {
  position: relative;
  height: min-content;
  margin: 30px 0px;
}

.volumeContainer_item {
  cursor: pointer;

  @include flex-column;
  position: relative;
  flex: 0 1 27px;
}

.volumeIcon {
  background: var(--src) center center / contain no-repeat;
}

.volumeIcon_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
}

.volumeContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 11px;
}

.volumeContainer_item1 {
  @include flex-column;
  justify-content: center;
  position: relative;
  flex: 0 1 83px;

}

.volumeBar {
  display: flex;
  background-color: rgba(117, 89, 58, 0.25);
  border-radius: 3px 3px 3px 3px;
}

.volumeBar_layout {
  position: relative;
  height: 8px;
  margin: 7.5px 0px;
}

.volumeBar_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 48px;
}

.vloumProgress {
  background-color: rgb(241, 191, 112);
  border-radius: 3px 3px 3px 3px;
}

.vloumProgress_layout {
  position: relative;
  height: 8px;
  width: 48px;
  min-width: 48px;
}

.volumeBar_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 8px;
}

.volumeDot {
  background: var(--src) center center / contain no-repeat;
}

.volumeDot_layout {
  position: relative;
  height: 8px;
  width: 8px;
  min-width: 8px;
}

.volumeBar_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 27px;
}

.playerFlex_spacer5 {
  @include flex-column;
  position: relative;
  flex: 0 1 35px;

  @include lg {
    flex: 0 1 25px;
    display: none;
  }

  @include sm {
    flex: 0 0 20px;
    order: 15;
  }
}

.smallGridContainer {
  @include sm {
    grid-column: 4/5;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
  }
}

.playerFlex_item7 {
  @include flex-column;
  position: relative;
  flex: 0 1 22px;

  @include sm {
    width: 32px;
    height: 32px;
  }
}

.openIcon {
  background: var(--src) center center / contain no-repeat;

  cursor: pointer;
}

.openIcon_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
  margin: 30px 0px 30px 0px;

  @include sm {
    margin: 0px;
    height: 32px;
    width: 32px;
    min-width: 32px;
  }

}

.playerFlex_item8 {
  @include flex-column;
  position: relative;
  flex: 0 1 22px;

  @include max {
    display: none;
  }

  @include sm {
    display: flex;
    width: 32px;
    height: 32px;
  }
}

.othersIcon {
  background: var(--src) center center / contain no-repeat;
}

.othersIcon_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;

  @include sm {
    height: 32px;
    width: 32px;
    min-width: 32px;
  }
}

.playerFlex_spacer6 {
  @include flex-column;
  position: relative;
  flex: 0 1 41px;

  @include lg {
    flex: 1 1 30px;
  }

  @include md {
    display: none;
  }

  @include sm {
    flex: 0 0 20px;
    order: 16;
  }
}


.rotate {
  transform: rotate(180deg);
}


.imgContainer {
  width: 75%;

  overflow: hidden;
}

.audioBookImgContainer {
  width: 100%;
  padding-top: 75%;

  overflow: hidden;

  margin: 0px auto;

  @include md {
    padding-top: 100%;
  }
}

.videoThumbnailContainer {
  width: 100%;
  padding-top: 75%;

  @include md {
    width: 70px;
    height: 70px;
  }
}

.videoThumbnail {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  @include md {
    transform: translate(-12.5%, -50%);
    border-radius: 20px;
  }
}

.audio {
  position: absolute;
  top: 0;
  left: 0;
}


.thumbnail {
  width: 100%;
  vertical-align: top;
  object-fit: cover;
  object-position: center top;
}

.thumbnail_layout {
  position: absolute;
  top: 0;
  left: 12.5%;

  @include md {
    left: 0;
  }
}


.squareThumbnail {
  width: 75%;
  max-width: 72px;
  height: 100%;
  object-fit: cover;
  object-position: center top;

  @include md {
    width: 100%;
  }
}

.disk {
  position: absolute;
  height: 90%;
  width: 75%;
  top: 5%;
  right: 0px;
  background: var(--src) center center / contain no-repeat;

  @include md {
    display: none;
  }
}

.shadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: transparent;
}

.moreMenuContainer {
  position: absolute;
  bottom: calc(100% + 80px);
  right: 10px;
  border-radius: 16px;


  background-color: rgba(172, 155, 137, 1);
  padding: 0px 10px;

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    right: 12px;

    border: 8px solid transparent;
    border-top: 6px solid rgba(172, 155, 137, 0.901);
    border-bottom: none;
  }

  &.moreInLand {
    display: flex;
  }
}

.rowContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-bottom: 1px solid #fff;

  &:last-child {
    border-bottom: none;
  }

  &.landRowContent {
    border-bottom: none;
    min-width: 70px;
  }
}

.chapterBtn {
  background: var(--src) center center / contain no-repeat;

  width: 44px;
  height: 44px;

  &.next {
    transform: rotate(180deg);
  }
}

.transColor {
  color: transparent;
  margin-right: 2px;
}

.orangeText {
  color: #f1bf70;
}