@import "utils";

$font_type0: 600 40px/1.05 'Noto sans TC', Helvetica, Arial, serif;
$font_type1: 500 28px/1.2 'Noto sans TC', Helvetica, Arial, serif;
$font_type2: 500 24px/1.5  'Noto sans TC', Helvetica, Arial, serif;
$font_type3: 20px/1.5 'Noto sans TC', Helvetica, Arial, serif;

.pageContainer {
  width: calc(100% - 20px);
  max-width: 1200px;
  
  border-radius: 10px;
  margin: 20px auto;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
  @include md {
    grid-template-columns: 1fr;
  }

  .leftContainer {
    position: sticky;
    top: 150px;
    width: 100%;
    height: min-content;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09803921568627451);
    border-radius: 10px;
    overflow: hidden;
    @include md {
      display: none;
    }
    .sectionName {
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 16px;
      padding: 20px;
      border-bottom: 1px solid #f2f2f2;
      background-color: #fff;
      color: #333333;
      
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }
      &.activeSection {
        background-color: #FDF8F0;
      }
      .arrowRight {
        width: 24px;
        height: 24px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .rightContainer {
    width: 750px;
    height: 100%;
    margin: 0 20px;
    @include md {
      width: calc(100% - 40px);
    }
    .pageTitle {
      display: flex;
      padding-bottom: 10px;
      @include font-face($font_type2, rgb(117, 89, 58), 0px);
      font-weight: 600;
      font-size: 24px;
      @include sm {
        justify-content: center;
        text-align: center;
      }
      &.subTitle {
        font-size: 20px;
        padding-bottom: 0px;
      }
    }
    .selectContainer {
      display: none;
      @include md {
        display: block;
        margin-bottom: 20px;
        position: relative;
      }
      @include xs {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .nowSection {
        width: 250px;
        height: 48px;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09803921568627451);
        background-color: #fff;
        border-radius: 5px;
        padding: 8px 10px;
        display: flex;
        align-items: center;
        justify-content: center;


        cursor: pointer;
      }
      .bgGray {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background-color: transparent;

        z-index: 1000;
      }

      .selects {
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        width: 250px;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09803921568627451);
        border-radius: 10px;
        overflow: hidden;

        z-index: 1000;
        .select {
          width: 100%;
          height: 48px;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #f2f2f2;
          text-align: center;
          background-color: #fff;

          cursor: pointer;
          &.active {
            background-color: #FDF8F0;
          }
        }
      }
    }
    .pageTitle_layout {
      position: relative;
      height: min-content;
    }
    .cardContainer {
      margin-top: 10px;
      padding: 10px;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
    }
  }
}

// NOTE: card

.flex27 {
  @include flex-column;
  background-color: rgb(227, 222, 216);

  cursor: pointer;
}
.flex27_layout {
  position: relative;
  min-height: 60px;
  margin: 0px 0px 20px;
}
.flex28 {
  display: flex;
}
.flex28_layout {
  position: relative;
  height: 100%;
  flex-grow: 1
}
.flex28_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
  @include sm {
    // display: none;
  }
}
.box27 {
  background-color: rgb(117, 89, 58);
}
.box27_layout {
  position: relative;
  height: 100%;
}
.flex28_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 15px;
  @include sm {
    min-width: 12px;
    // display: none;
  }
}
.flex28_item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.medium_title3 {
  @include v-center-content;
  @include font-face($font_type2, rgb(117, 89, 58), 0px);
  font-size: 18px;
  font-weight: 700;
  @include web {
    font-size: 16px;
    text-align: left;
  }
}
.medium_title3_layout {
  position: relative;
  flex-grow: 1;
  min-height: 29px;
  flex-shrink: 0;
}
.flex28_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 30px;
  @include sm {
    min-width: 20px;
  }
  @include tn {
    min-width: 5px;
  }
}
.flex28_item2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 44px;
}
.icon6 {
  background: var(--src) center center / contain no-repeat;
  width: 100%;
  height: 100%;
  transform: rotate(0deg);;
  transition: transform .2s linear;
}
.rotate {
  transform: rotate(-180deg);
}

.cover_block5 {
  @include flex-column;
  background-color: rgb(255, 255, 255);
}
.cover_block5_layout {
  position: relative;
  margin: 0px;
  overflow: hidden;
}
.openContainer {
  margin-bottom: 20px;
}
.subtitle3 {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  @include font-face($font_type3, rgb(117, 89, 58), 0px);
  white-space: pre-wrap;
  @include sm {
    font-size: 18px;
    text-align: left;
  }
  @include xxs {
    font-size: 14px;
  }
}
.subtitle3_box {
  @include sm {
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include xxs {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.subtitle3_box_layout {
  position: relative;
  height: min-content;
  margin: 20px;
}


.contentContainer {
  // padding: 0px 10px;
  line-height: 1.5rem;
  background-color: #fff;
  border-radius: 10px;
  .content{
    margin: 10px 0px;
  }
  .listItem {
    position: relative;
    padding-left: 20px;
    margin: 10px 0;
    .listNum {
      position: absolute;
      top: 0;
      left: 0;
    }
    .listDot {
      position: absolute;
      // NOTE: use percentage.
      top: calc(12px - 3px);
      left: 0;

      width: 6px;
      height: 6px;
      border-radius: 50%;

      background-color: #000;
    }
  }
  .bolder {
    font-weight: 600;
  }
  .underline {
    text-decoration: underline;
  }
  .aLink {
    border-bottom: 0.05em solid rgba(55, 53, 47, 0.4);
    opacity: 0.7;
    word-break: break-all;
    margin: 0px 5px;
  }
  .redText {
    color: #D44C47;
  }
}