/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
@import "utils";

$font_type0: 18px/0.55 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 20px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type2: 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

$font_type4: 16px/0.6 "Noto Sans TC", Helvetica, Arial, serif;


.keyword {
  width: 100%;
  // height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  .input {
    width: 100%;
    border: none;
    line-height: normal;
    &:active, &:focus {
      outline: none;
    }
  }

  .hotKeywordsContainer {
    width: 400px;
    background-color: #fff;
    box-sizing: border-box;

    border: 1px solid #ccc;

    transform: translate(-50%, 10px);
    position: absolute;
    left: 50%;
    top: 100%;

    display: none;

    &.show {
      display: block;
    }

    .title {
      padding: 0px 14px;
    }

    .hotKeywordsItemsContainer {
      padding: 0px 4px;
      display: flex;
      flex-wrap: wrap;

      .hotKeywordsItem {
        padding: 0px 8px 10px;
        width: 25%;

        .hotKeywordsBackground {
          padding: 4px 0px;
          text-align: center;
          background-color: #eee;
          border-radius: 10px;
          display: block;
          cursor: pointer;
        }
      }
    }
  }
}

.block1 {
  @include flex-column;
}
.block1_layout {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.block {
  @include flex-column;
  background-color: rgb(204, 204, 204);
  border: 1px solid rgba(151, 151, 151, 0.403);
}
.block_layout {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  min-height: 772px;
  flex-shrink: 0;
}
.popularBubbleContainer {
  @include flex-column;
  width: 534px;
  max-height: 308px;
  position: absolute;
  top: 40px;
  left: -50px;
  @include sm {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    max-height: unset;
    background-color: rgb(242, 242, 242);
  }
}
.popularBubbleContainer_item {
  position: relative;
  z-index: 1;
  @include flex-column;
  @include sm {
    flex: 0 0 auto;
    display: none;
  }
}
.arrow {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  transform: translateY(1px);
}
.arrow_layout {
  position: relative;
}
.popularBubbleContainer_item1 {
  z-index: 0;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09803921568627451);
  @include flex-column;
  position: relative;
  border-radius: 10px;
  @include sm {
    flex: 1 0 auto;
  }
}
.contentContainer {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  @include max {
    border-radius: 10px;
  }
  @include sm {
    border-radius: 0px;
    background-color: #f2f2f2;
  }
}
.contentContainer_layout {
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
}
.contentFlex {
  @include flex-column;
}
.contentFlex_layout {
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 16px 32px;
  @include xxs {
    margin: 16px 20px;
  }
  @include tn {
    margin: 16px 15px;
  }
}
.contentFlex_item {
  @include flex-column;
  position: relative;
  @include max {
    flex: 0 1 auto;
    display: none;
  }
  @include sm {
    display: flex;
  }
}
.searchBarContainer {
  display: grid;
  grid-template-columns: 1fr auto;

  grid-gap: 10px;
}
.searchBarContainer_layout {
  position: relative;
  height: min-content;
}
.searchBarContainer_item {
  @include flex-column;
  position: relative;
  width: 100%;
}
.searchInputContainer {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border-radius: 22px 22px 22px 22px;
}
.searchInputContainer_layout {
  position: relative;
  height: min-content;
  @include sm {
    height: 100%;
    justify-content: center;
  }
}
.inouFlex {
  display: flex;
}
.inouFlex_layout {
  position: relative;
  height: min-content;
  margin: 12px 20px;
  @include sm {
    margin: 0px 10px;
  }
}
.inouFlex_item {
  @include flex-column;
  position: relative;
  margin-right: 5px;

  justify-content: center;
}
.searchIcon {
  background: var(--src) center center / contain no-repeat;
}
.searchIcon_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
}
.inouFlex_spacer {
  @include flex-column;
  position: relative;
}
.searchInput {
  @include v-center-content;
  @include font-face($font_type0, #000, 0px);
  &::placeholder {
    color: rgb(174, 174, 174);
  }

  border: none;
  outline: none;
}
.searchInput_layout {
  position: relative;

  width: 100%;
}
.searchBarContainer_spacer {
  @include flex-column;
  position: relative;
  min-width: 10px;
}
.searchBarContainer_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 88px;
  @include xxs;
}
.cancelBtn {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border-radius: 22px 22px 22px 22px;

  cursor: pointer;
}
.cancelBtn_layout {
  position: relative;
  flex-grow: 1;
  min-height: 44px;
  flex-shrink: 0;
  padding: 0px 10px;
}
.cancelText {
  @include v-center-content;
  justify-content: center;
  font: $font_type0;
  color: rgb(52, 52, 52);
  text-align: center;
  letter-spacing: 0px;

  white-space: nowrap;
}
.cancelText_layout {
  position: relative;
  flex-grow: 1;
  min-height: 10px;
  flex-shrink: 0;
  width: 46.59%;
  margin: 17.5px auto 16.5px;
  @include sm {
    margin: 0px auto;
  }
  @include xxs {
    width: 50.62%;
  }
  @include tn {
    width: 57.75%;
  }
}
.contentFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 16px;
}
.contentFlex_item1 {
  @include flex-column;
  position: relative;
  @include sm {
    flex: 1 0 auto;
  }
}
.popularContainer {
  @include flex-column;
}
.popularContainer_layout {
  position: relative;
  height: min-content;
}
.popularTitle {
  @include font-face($font_type2, rgb(0, 0, 0), 0px);
  opacity: 0.85;
  @include web {
    font-size: 14px;
  }
}
.popularTitle_layout {
  position: relative;
  height: min-content;
}
.popularContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 10px;
}
.listContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 10px;
}
.listContainer_layout {
  position: relative;
  flex: 1 1 auto;
  height: min-content;
  margin: 2px 0px 0px;
}
.listContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;

  cursor: pointer;
}
.listItem {
  @include flex-column;
  background-color: rgb(171, 154, 137);
  border-radius: 16.5px 16.5px 16.5px 16.5px;
}
.listItem_layout {
  position: relative;
  height: min-content;
}
.itemText {
  @include v-center-content;
  justify-content: center;
  font: $font_type2;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
  @include web {
    font-size: 14px;
  }
}
.itemText_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
  margin: 6px 13px;
}
.contentFlex_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 16px;
  @include sm {
    display: none;
  }
}


.contentFlexBox_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 20px;
  @include max {
    display: none;
  }
  @include sm {
    display: flex;
  }
}
.searchTiny {
  background: var(--src) center center / contain no-repeat;

  cursor: pointer;
}
.searchTiny_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin: 13px 0px;
}
.contentFlexBox_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 32px;
  @include md {
    flex: 0 1 20px;
  }
  
  @include sm {
    min-width: 22px;
    display: none;
  }
  @include xxs {
    flex: 1 1 auto;
    min-width: 60px;
    display: flex;
  }
  @include tn {
    display: none;
  }
}
.contentFlexBox_item2 {
  @include flex-column;
  position: relative;
  flex: 1 1 399px;
  @include lg {
    flex: 1 1 auto;
  }
  @include sm {
    display: none;
  }
}
.searchContainer {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.058823529411764705);
  border-radius: 22px 22px 22px 22px;
}
.searchContainer_layout {
  position: relative;
  height: min-content;
  margin: 4px 0px;
}
.innerContent {
  display: flex;
  align-items: center;
}
.innerContent_layout {
  position: relative;
  flex-grow: 1;
  margin: 5.5px 20px;
}
.innerContent_item {
  @include flex-column;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 0 0 auto;
  min-width: 20px;
}
.searchIcon {
  background: var(--src) center center / contain no-repeat;
}
.searchIcon_layout {
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
}
.innerContent_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 12px;
}
.innerContent_item1 {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include md;
}
.placeholder {
  @include v-center-content;
  @include font-face($font_type4, rgb(174, 174, 174), 0px);
}
.placeholder_layout {
  position: relative;
  flex-grow: 1;
}

.bubbleFullScreen {
  display: none;
  @include md {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: block;
  }
}

.d_none {
  display: none;
}

.d_block {
  display: block;
}