.sectionContainer {

}

.swiperContainer {
  width: 100%;
}

.slidePre {
  display: none;
}

.slideNext {
  display: none;
}

.isLoading {
  display: none !important;
}

@media screen and (min-width: 768px) {
  .slidePre {
    display: block;
    position: absolute;
    top: 50%;
    left: 17%;
  
    transform: translate(-100%, -50%);
    height: min(20%, 60px);
    width: min(calc(20% * 0.7), 42px);
    background: rgba(255, 255, 255 ,0.5);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

    border-radius: 13px 0 0 13px;

    z-index: 10;
    cursor: pointer;
  }
  
  .slideNext {
    display: block;
    position: absolute;
    top: 50%;
    right: 17%;
  
    transform: translate(100%, -50%);
    height: min(20%, 60px);
    width: min(calc(20% * 0.7), 42px);
    
    background: rgba(255, 255, 255 ,0.5);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

    border-radius: 0 13px 13px 0;

    z-index: 10;
    cursor: pointer;
  }
  
  .slide_pre {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
    min-width: 22px;
    minheight: 22px;
    z-index: 10;
    cursor: pointer;
  } 
  
  .slide_next {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 22px;
    height: 22px;
    z-index: 10;
    cursor: pointer;
  }
}

@media screen and (min-width: 1060px) {
  .slidePre {
    top: 50%;
    left: calc(50% - 350px);
  
    transform: translate(-100%, -75%);
  }

  .slideNext {
    top: 50%;
    right: calc(50% - 350px);
  
    transform: translate(100%, -75%);
  }
}

.containerStyles {
  position: relative;
  height: calc(100% - 80px);
  max-height: 750px;
  width: calc(100% - 80px);
  max-width: 1200px;
  overflow: hidden;

  border-radius: 10px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09803921568627451);
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.titleContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 5px;
  padding-left: 20px;

  border-bottom: 1px solid #f2f2f2;
}

.announceModalTitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 25px;
  font-weight: 600;
  text-align: left;
  color: #75593a;
}

.announceModalClose {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.quillContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 24px;
}

@media screen and (max-width: 768px) {
  .containerStyles {
    max-width: unset;
    max-height: unset;
    height: 100%;
    width: 100%;
  }
}