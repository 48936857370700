@import "utils";

$font_type0: 500 32px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.celebritySection_layout {
  position: relative;
  height: min-content;
}
.celebritySection {
  @include flex-column;
  background: var(--src) center center / cover no-repeat;
}
.contentContainer {
  @include flex-column;
}
.contentContainer_layout {
  position: relative;
  height: min-content;
  width: 100%;
  max-width: 1200px;
  margin: 61px auto;
  @include xs {
    margin: 30px auto;
  }
}
.innerFlexBox {
  @include flex-column;
}
.innerFlexBox_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  max-width: 1200px;
  width: calc(100% - 100px);
  margin: 0px 50px;
  @include lg {
    margin: 0px 45px;
    width: calc(100% - 90px);
  }
  @include md {
    margin: 0px 40px;
    width: calc(100% - 80px);
  }
  @include sm {
    margin: 0px 25px;
    width: calc(100% - 50px);
  }
  @include xs {
    margin: 0px 20px;
    width: calc(100% - 40px);
  }
  @include tn {
    margin: 0px 15px;
    width: calc(100% - 30px);
  }
}
.innerFlexBox_item {
  @include flex-column;
  position: relative;
  width: 100%;
}
.sectionTitle {
  @include h-center-content;
  font: $font_type0;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
  @include web {
    font-size: 24px;
    text-align: center;
  }
  @include xs {
    font-size: 22px;
  }
}
.sectionTitle_layout {
  position: relative;
  height: min-content;
}
.innerFlexBox_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 28px;
}
.flex93 {
  @include flex-column;
}
.flex93_layout {
  position: relative;
  height: min-content;
  width: 100%;
}
.flex94_layout {
  position: relative;
  height: min-content;
  flex-shrink: 0;
  width: 100%;
  margin: 0px 0px;
  @include lg {
    width: 95.8%;
    margin: 0px auto;
  }
}
.flex93_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 20px;
}
.flex104 {
  display: flex;
}
.flex104_layout {
  position: relative;
  height: min-content;
  width: 116px;
  min-width: 116px;
  margin: 0px auto;
}
.flex104_item {
  @include flex-column;
  position: relative;
  flex: 0 1 8px;
}
.icon {
  background: var(--src) center center / contain no-repeat;
}
.icon_layout {
  position: relative;
  height: 8px;
  width: 8px;
  min-width: 8px;
}
.flex104_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 7px;
}
.flex104_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 8px;
}
.flex104_spacer2 {
  @include flex-column;
  position: relative;
  flex: 0 1 8px;
}
.flex104_spacer3 {
  @include flex-column;
  position: relative;
  flex: 0 1 8px;
}
.innerFlexBox_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 45px;
  @include md {
    min-height: 40px;
  }
  @include sm {
    min-height: 35px;
  }
}
