// 音量軸.

.inputRange {
  background: rgba(117, 89, 58, 0.25);
  height: 5px;
  border-radius: 4px;
}

.selfVolumeTrack {
  background-color: transparent;

  &::after {
    display: block;
    content: '';
    width: 100%;
    height: 20px;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    z-index: 1;
  }
}

.selfVolumeSlider {
  background: url('../../../../../../assets/player/dot.png') center center / contain no-repeat;
  border: none;

  &::after {
    display: block;
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
  }
}

.selfVolumeActiveTrack {
  background: transparent;
}

.selfVolumeActiveTrack::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;

  width: calc(100%);
  background-color: #f1bf70;
  height: 5px;
  border-radius: 4px;

}
