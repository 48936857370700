*,
*::before,
*::after {
    box-sizing: border-box;
}

h5 {
    margin: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
    -webkit-overscroll-behavior: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    background-color: rgb(242, 242, 242);
}

input,
textarea {
    font-size: initial;
}

select {
    -webkit-appearance: none;
}


.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.wrap {
    white-space: pre-wrap;
    word-wrap: break-word;
    line-break: normal;
    /* anywhere */
}

.nowrap {
    word-wrap: nowrap;
    white-space: nowrap;
}

a {
    text-decoration: none;
    color: #000;
}

a:hover {
    color: unset;
}

/* NOTE: from px-code */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
figure {
    display: block;
    font-size: 1em;
    font-weight: normal;
    margin: 0;
    border-width: 0;
    opacity: 1;
}

ul,
ul {
    display: block;
    margin: 0;
    padding: 0;
}

li {
    display: block;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: 'Noto sans TC', sans-serif,
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
    -webkit-overscroll-behavior: none;
    -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
}

body {
    background-color: #fff;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.page-header {
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

@media (max-width: 99999px) {
    .max\:show {
        display: flex;
        flex-direction: column;
    }

    .xxxl\:show {
        display: none;
    }

    .xxl\:show {
        display: none;
    }

    .xl\:show {
        display: none;
    }

    .lg\:show {
        display: none;
    }

    .md\:show {
        display: none;
    }

    .sm\:show {
        display: none;
    }

    .xs\:show {
        display: none;
    }

    .max\:hide {
        display: none;
    }
}

@media (max-width: 2999px) {
    .xxxl\:show {
        display: flex;
    }

    .xxxl\:hide {
        display: none;
    }
}

@media (max-width: 1919px) {
    .xxl\:show {
        display: flex;
    }

    .xxl\:hide {
        display: none;
    }
}

@media (max-width: 1399px) {
    .xl\:show {
        display: flex;
    }

    .xl\:hide {
        display: none;
    }
}

@media (max-width: 1199px) {
    .lg\:show {
        display: flex;
    }

    .lg\:hide {
        display: none;
    }
}

@media (max-width: 991px) {
    .md\:show {
        display: flex;
    }

    .md\:hide {
        display: none;
    }
}

@media (max-width: 767px) {
    .sm\:show {
        display: flex;
    }

    .sm\:hide {
        display: none;
    }
}

@media (max-width: 575px) {
    .xs\:show {
        display: flex;
    }

    .xs\:hide {
        display: none;
    }
}

/* NOTE: basic setting from px-code */
/* fonts.css */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* FIXME: for more specific break points */
.main-flex-subscriber> :nth-child(3) {
    position: sticky !important;
    top: 0px;
    z-index: 100;
    transition: top .2s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.main-flex-subscriber> :nth-child(3).show {
    position: sticky !important;
    top: 78px;
    transition: top .65s cubic-bezier(0.215, 0.610, 0.355, 1);
}

@media (max-width: 1399px) {
    .main-flex-subscriber> :nth-child(3) {
        top: 0px;
    }

    .main-flex-subscriber> :nth-child(3).show {
        top: 78px;
    }
}

@media screen and (max-width: 1199px) {
    .main-flex-subscriber> :nth-child(3) {
        top: 0px;
    }
    .main-flex-subscriber> :nth-child(3).show {
        top: 68px;
    }
}

@media screen and (max-width: 767px) {
    .main-flex-subscriber> :nth-child(3) {
        top: 0px;
    }
    .main-flex-subscriber> :nth-child(3).show {
        top: 64px;
    }
}

@media screen and (max-width: 575px) {
    .main-flex-subscriber> :nth-child(3) {
        top: 0px;
    }
    .main-flex-subscriber> :nth-child(3).show {
        top: 60px;
    }
}

@media screen and (max-width: 479px) {
    .main-flex-subscriber> :nth-child(3) {
        top: 0px;
    }
    .main-flex-subscriber> :nth-child(3).show {
        top: 55px;
    }
}

.header-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 1000;
}

@media (hover: hover) and (pointer: fine) {

    .block img.hover-zoom,
    .block div.hover-zoom {
        transition: all 0.5s;
    }

    .block:hover img.hover-zoom,
    .block:hover div.hover-zoom {
        transform-origin: center;
        transform: scale(1.2);
    }


    .block .like {
        transition: all 0.5s;
        top: -20px;
        opacity: 0.0;
    }

    .block:hover .like {
        top: 10px;
        opacity: 1.0;
    }
}



.root> :nth-child(1) {
    /*background-color: yellow;*/
    position: sticky;
    top: 0px;
    z-index: 100;
}

.MainFlex> :nth-child(3) {
    /*background-color: red;*/
    position: sticky;
    top: 51px;
    z-index: 99;
}

.grid2>div {
    /* flex-basis: 48% !important; */
    flex-basis: calc((100% - 10px) / 2) !important;
}

.grid4>div {
    flex-basis: calc((100% - 30px) / 3) !important;
}

.grid5>div {
    flex-basis: calc((100% - 28px) / 2) !important;
}



/* XL */
@media (max-width: 1399px) {
    .grid>div {
        /*flex-basis: 22% !important;*/
        flex-basis: calc((100% - 30px) / 4) !important;
    }

    .grid2>div {
        /* flex-basis: 48% !important; */
        flex-basis: calc((100% - 10px) / 2) !important;
    }

    .grid4>div {
        flex-basis: calc((100% - 30px) / 3) !important;
    }
}

/* LG */
@media (max-width: 1199px) {
    .grid>div {
        /* flex-basis: 30% !important; */
        flex-basis: calc((100% - 20px) / 4) !important;
    }

}

@media (max-width: 1023px) {
    .grid>div {
        /* flex-basis: 30% !important; */
        flex-basis: calc((100% - 20px) / 3) !important;
    }

}

/* MD */
@media (max-width: 991px) {
    .grid5>div {
        flex-basis: 100% !important;
    }
}

/* SM */
@media (max-width: 767px) {
    .grid>div {
        /* flex-basis: 48% !important; */
        flex-basis: calc((100% - 10px) / 2) !important;
    }

    .grid2>div {
        flex-basis: 100% !important;
    }

    .grid5>div {
        flex-basis: calc((100% - 28px) / 2) !important;
    }
}

/* XS */
@media (max-width: 575px) {
    .grid>div {
        flex-basis: 100% !important;
    }

    .grid4>div {
        flex-basis: calc((100% - 15px) / 2) !important;
    }

    .grid5>div {
        flex-basis: calc((100% - 0px) / 1) !important;
    }
}

.grid3>div {
    flex-basis: calc((100% - 29px) / 2) !important;
}