@import "utils";

$font_type0: 16px/1.5 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 14px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.cardContainer {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(228, 228, 228);
  border-radius: 15px 15px 15px 15px;
}
.cardContainer_layout {
  position: relative;
  height: 100%;
}
.contentFlex {
  @include flex-column;
}
.contentFlex_layout {
  position: relative;
  height: 100%;
  margin: 20px 16px;
  @include lg {
    margin: 18px 14px;
  }
  @include md {
    margin: 15px 18px;
  }
  @include sm {
    margin: 15px 16px;
  }
  @include xxs {
    margin: 15px 12px;
  }
}
.contentFlex_item {
  @include flex-column;
  position: relative;
}
.commaIcon {
  @include flex-column;
}
.commaIcon_layout {
  position: relative;
  height: min-content;
  margin: 0px 9px;
}
.hero_title {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
}
.hero_title_layout {
  position: relative;
  width: 15px;
  min-width: 15px;
}
.contentFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 35px;
  @include md {
    min-height: 50px;
  }
  @include xs {
    min-height: 40px;
  }
  @include tn {
    min-height: 35px;
  }
}
.content {
  @include v-center-content;
  @include font-face($font_type0, rgb(51, 51, 51), 0px);
  @include lg {
    font-size: 15px;
    text-align: left;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.content_layout {
  position: relative;
  height: min-content;
  margin: 0px 9px;
  @include lg {
    margin: 0px 8px;
  }
  @include md {
    margin: 0px 7px;
  }
  @include sm {
    margin: 0px 6px;
  }
  @include xs {
    margin: 0px 5px;
  }
}
.contentFlex_spacer1 {
  @include flex-column;
  position: relative;
  flex: 1 0 auto;
  min-height: 35px;
  @include md {
    min-height: 30px;
  }
  @include xxs {
    min-height: 25px;
  }
}
.celebrityContainer {
  display: flex;
}
.celebrityContainer_layout {
  position: relative;
  height: min-content;
  width: 85.55%;
  margin: 0px 0% 4px 14.45%;
  @include lg {
    width: 100%;
    margin: 0px 0px 5px;
  }
}
.celebrityContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.celebrityContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 1 225px;
}
.infoContainer {
  @include flex-column;
}
.infoContainer_layout {
  position: relative;
  height: min-content;
  margin: 4px 0px;
  @include lg {
    margin: 4px 0px 9px;
  }
  @include md {
    margin: 4px 0px 0px;
  }
}
.celebrityName {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type1;
  color: rgb(117, 89, 58);
  text-align: right;
  letter-spacing: 0px;
  font-weight: 500;
  @include web {
    font-size: 12px;
    text-align: right;
  }
}
.celebrityName_layout {
  position: relative;
  min-height: 20px;
  flex-shrink: 0;
  @include lg {
    margin: 0px 0px 5px 25px;
  }
  @include md {
    margin: 0px 0px 5px 13px;
  }
  @include sm {
    margin: 0px 0px 5px 5px;
  }
  @include xxs {
    margin: 0px 0px 5px 8px;
  }
  @include tn {
    margin: 0px 0px 5px 5px;
  }
}
.celebrityDescription {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type1;
  color: rgb(117, 89, 58);
  text-align: right;
  letter-spacing: 0px;
  @include lg {
    font-size: 14px;
    text-align: right;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.celebrityDescription_layout {
  position: relative;
  height: min-content;
  margin: 3px 0px 0px;
  @include md {
    margin: 3px 0px 0px 13px;
  }
  @include sm {
    margin: 3px 0px 0px 5px;
  }
  @include xxs {
    margin: 3px 0px 0px 8px;
  }
  @include tn {
    margin: 3px 0px 0px 5px;
  }
}
.celebrityContainer_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 15px;
}
.celebrityContainer_item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 50px;
}
.avatar {
  background: var(--src) center center / contain no-repeat;
}
.avatar_layout {
  position: relative;
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 50%;
  margin: 3px 0px 0px;
  @include lg {
    margin: 5px 0px 0px;
  }
}
