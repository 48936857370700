.cardContainer {
  width: 100%;
  cursor: pointer;
}

.imgContainer {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;

  box-shadow: 0px 0px 9px 0px rgba(49, 37, 22, 0.2078);
}