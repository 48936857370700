@import "utils";

.container {
  width: 100%;
  height: 40px;
  position: relative;

  display: flex;
  align-items: center;

  .inputContainer {
    height: 100%;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    width: 100%;
  

    .form {
      width: 100%;
      height: 100%;
      display: block;

      input {
        margin: 0px;
        padding: 4px;
        width: 100%;
        height: 100%;
        border: 0px;
        box-sizing: border-box;
      }
    }

    &.notValid {
      border-color: #ff4d4f;
    }
  }
}
.messageContainer {
  font-size: 14px;
  color: #ff4d4f;
  text-align: right;
}


.showPassword {
  background: var(--src) center center / contain no-repeat;
}
.showPassword_layout {
  position: relative;
  height: 22px;
  width: 22px;
  min-width: 22px;
  @include xs {
    height: 20px;
    width: 20px;
    min-width: 20px;
    margin: 0px;
  }
  @include tn {
    height: 18px;
    width: 18px;
    min-width: 18px;
  }
}