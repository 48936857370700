@import "utils";

.signInContainer {
  @include xs {
    height: 100%;
    width: 100%;
  }
}

.highestLevel {
  z-index: 2000;
}

.contentContainerStyles {
  border-radius: 10px;
  width: 569px;
  min-height: 644px;
  @include xs {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

