@import "utils";

$font_type0: 500 32px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.learnedSection {
  @include flex-column;
}

.learnedSection_layout {
  position: relative;
  height: min-content;
  width: 99.82%;
  max-width: 1200px;
  margin: 30px auto;
}

.learnedContent_layout {
  position: relative;
  height: min-content;
  width: calc(100% - 100px);
  min-width: 0px;
  max-width: 1100px;
  margin: 0px 50px;
  @include lg {
    margin: 0px 45px;
    width: calc(100% - 90px);
  }
  @include md {
    margin: 0px 40px;
    width: calc(100% - 80px);
  }
  @include sm {
    margin: 0px 25px;
    width: calc(100% - 50px);
  }
  @include xs {
    margin: 0px 20px;
    width: calc(100% - 40px);
  }
  @include tn {
    margin: 0px 15px;
    width: calc(100% - 30px);
  }
}
.learnedContent {
  @include flex-column;
}
.learnedContent_item {
  @include flex-column;
  position: relative;
  width: 100%;
}
.titleFlex {
  display: flex;
}
.titleFlex_layout {
  position: relative;
  height: min-content;
  margin: 0px 4px;
  @include lg {
    margin: 0px 5px;
  }
}
.titleFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 181px;
}
.titleContainer {
  display: flex;
}
.titleContainer_layout {
  position: relative;
  flex-grow: 1;
  min-height: 40px;
  flex-shrink: 0;
}
.titleContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 1 32px;
}
.learnedIcon {
  background: var(--src) center center / contain no-repeat;
}
.learnedIcon_layout {
  position: relative;
  height: 36px;
  width: 36px;
  min-width: 36px;
  margin: 6.5px 0px;
  @include lg {
    margin: 6px 0px;
  }
  @include md {
    height: 32px;
    width: 32px;
    min-width: 32px;
    margin: 5px 0px;
  }
  @include xs {
    height: 28px;
    width: 28px;
    min-width: 28px;
  }
}
.titleContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 10px;
  @include xs {
    min-width: 8px;
  }
  @include xxs {
    min-width: 4px;
  }
}
.titleContainer_item1 {
  @include flex-column;
  position: relative;
}
.title {
  @include v-center-content;
  @include font-face($font_type0, rgb(117, 89, 58), 0px);
  @include web {
    font-size: 24px;
    text-align: left;
  }
  @include xs {
    font-size: 22px;
  }
}
.title_layout {
  position: relative;
  flex-grow: 1;
  min-height: 40px;
  flex-shrink: 0;
}
.titleFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 880px;
}
.titleFlex_item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 83px;
  cursor: pointer;
  @include sm {
    min-width: 64px;
  }
}
.moreBtn {
  display: flex;
}
.moreBtn_layout {
  position: relative;
  flex-grow: 1;
  min-height: 20px;
  flex-shrink: 0;
  @include lg {
    margin: 11px 0px 7px;
  }
  @include md {
    margin: 9px 0px 6px;
  }
  @include sm {
    margin: 8px 0px 5px;
  }
  @include xs {
    margin: 7px 0px 5px;
  }
  @include xxs {
    margin: 6px 0px 5px;
  }
}
.moreText {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type1;
  color: rgb(117, 89, 58);
  text-align: right;
  letter-spacing: 0px;
  @include lg {
    font-size: 15px;
    text-align: right;
  }
  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 13px;
  }
  @include xxs {
    font-size: 12px;
  }
}
.moreText_layout {
  position: relative;
  flex: 0 0 auto;
  min-height: 20px;
  flex-shrink: 0;
}
.moreBtn_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 5px;
}
.moreBtn_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 5px;
}
.arrowRight {
  background: var(--src) center center / contain no-repeat;
}
.arrowRight_layout {
  position: relative;
  flex-grow: 1;
  min-height: 10px;
  flex-shrink: 0;
  width: 24px;
  min-width: 24px;
  margin: 5px 0px;
  @include sm {
    width: 20px;
    min-width: 20px;
  }
}
.learnedContent_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-height: 33px;
  @include sm {
    min-height: 25px;
  }
}
.group {
  @include flex-column;
}
.group_layout {
  position: relative;
  height: min-content;
  width: 100% ;
}
.flex10_layout {
  position: relative;
  height: min-content;
  flex-shrink: 0;
  width: 100%;
  margin: 0px 0px;
  @include sm {
    width: 83.21%;
    margin: 0px auto;
  }
}

.leftArrow {
  position: absolute;
  top: 38%;
  left: 0px;

  transform: translate(-50%, -50%);
  z-index: 1;

  width: 40px;
  height: 40px;

  background: var(--src) center center / contain no-repeat;
  cursor: pointer;

  @include xs {
    left: 20px;
  }
}
.rightArrow {
  position: absolute;
  top: 38%;
  right: 0px;

  transform: translate(50%, -50%);
  z-index: 1;

  width: 40px;
  height: 40px;

  background: var(--src) center center / contain no-repeat;
  cursor: pointer;

  @include xs {
    right: 20px;
  }
}