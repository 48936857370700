@import "utils";

$font_type0: 500 24px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type1: 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$font_type2: 600 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;

.interestsInput {
  @include flex-column;
}
.interestsInput_layout {
  position: relative;
  min-height: 644px;
  flex-shrink: 0;
}
.contentFlex {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.14901960784313725);
  border-radius: 10px 10px 10px 10px;
}
.contentFlex_layout {
  position: relative;
  flex-grow: 1;
  min-height: 644px;
  flex-shrink: 0;
}
.titleContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.titleContainer_layout {
  position: relative;
  height: min-content;
  margin: 10px 10px 8px;
}
.titleContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
  @include lg;
}
.backIcon {
  background: var(--src) center center / contain no-repeat;
}
.backIcon_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
}
.titleContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 184.5px;
}
.titleContainer_item1 {
  @include flex-column;
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  flex: 0 0 auto;
}
.title {
  @include h-center-content;
  font: $font_type0;
  color: rgb(117, 89, 58);
  text-align: center;
  letter-spacing: 0px;
  @include web {
    font-size: 20px;
  }
  @include xs {
    font-size: 18px;
  }
}
.title_layout {
  position: relative;
  height: min-content;
}
.titleContainer_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 170.5px;
}
.titleContainer_item2 {
  @include flex-column;
  position: relative;
  flex: 0 1 44px;
}
.closeBtn {
  background: var(--src) center center / contain no-repeat;
}
.closeBtn_layout {
  position: relative;
  height: 44px;
  width: 44px;
  min-width: 44px;
}
.selectsContainer {
  @include flex-column;
  background-color: rgb(242, 242, 242);
}
.selectsContainer_layout {
  position: relative;
  flex: 1 1 auto;
  height: 477px;
  overflow: auto;
  flex-shrink: 0;
  margin: 1px 0px 0px;
}
.selectFlex {
  @include flex-column;
}
.selectFlex_layout {
  position: relative;
  flex-grow: 1;
  min-height: 437px;
  flex-shrink: 0;
  margin: 20px 15px;
}
.selectTitle {
  @include v-center-content;
  @include font-face($font_type1, rgb(51, 51, 51), 0px);
}
.selectTitle_layout {
  position: relative;
  height: min-content;
  margin: 2.5px 0px 0px;
}
.selectGrid {
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 15px;
}
.selectGrid_layout {
  position: relative;
  flex: 1 1 auto;
  height: 100%;
  margin: 21.5px 5px 0px;
}
.content_box5 {
  flex: 0 0 352px;
}
.content_box5_layout {
  position: relative;
  flex: 0 0 352px;
  height: min-content;
}
.newsPaperBtn {
  display: flex;

  cursor: pointer;
}
.newsPaperBtn_layout {
  position: relative;
  height: min-content;
  margin: 20px 5px 0px;
}
.newsPaperBtn_spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
}
.newsPaperBtn_item {
  @include flex-column;
  position: relative;
  flex: 0 1 18px;
  @include tn {
    flex: 0 1 14px;
  }
}
.checkBox {
  background: var(--src) center center / contain no-repeat;
}
.checkBox_layout {
  position: relative;
  height: 18px;
  width: 18px;
  min-width: 18px;
  margin: 1px 0px;
}
.newsPaperBtn_spacer1 {
  @include flex-column;
  position: relative;
  flex: 0 1 6px;
  @include xxs {
    flex: 0 1 4px;
  }
}
.newsPaperBtn_item1 {
  @include flex-column;
  position: relative;
}
.newsPaperText {
  @include font-face($font_type1, rgb(51, 51, 51), 0px);
  @include xxs {
    font-size: 14px;
    text-align: left;
  }
  @include tn {
    font-size: 11px;
  }
}
.newsPaperText_layout {
  position: relative;
  height: min-content;
  min-width: 0px;
  width: fit-content;
}
.noteContainer {
  @include flex-column;
  background-color: rgb(227, 222, 216);
  border-radius: 15px 15px 15px 15px;
}
.noteContainer_layout {
  position: relative;
  height: min-content;
  width: 81.04%;
  margin: 23px auto 0px;
}
.noteText {
  @include v-center-content;
  justify-content: center;
  font: $font_type1;
  color: rgb(118, 89, 58);
  text-align: center;
  letter-spacing: 0px;
  @include xxs {
    font-size: 14px;
    text-align: center;
  }
  @include tn {
    font-size: 12px;
  }
}
.noteText_layout {
  position: relative;
  height: min-content;
  margin: 12.5px 0px;
}
.btnsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.btnsContainer_layout {
  position: relative;
  height: min-content;
  width: 80.99%;
  margin: 30px auto;
}
.btnsContainer_item {
  @include flex-column;
  position: relative;
  flex: 0 1 215px;
}
.prevStepBtnContainer {
  @include flex-column;
  border: 2px solid rgb(117, 89, 58);
  border-radius: 22px 22px 22px 22px;

  cursor: pointer;
}
.prevStepBtnContainer_layout {
  position: relative;
  height: 44px;
}
.prevContainerText {
  @include h-center-content;
  font: $font_type1;
  color: rgb(117, 89, 58);
  text-align: center;
  letter-spacing: 0px;
}
.prevContainerText_layout {
  position: relative;
  height: 22px;
  width: 100%;
  margin: 10px 0px 0px;
}
.btnsContainer_spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 30px;
}
.submitBtnContainer {
  @include flex-column;
  background-color: rgb(241, 191, 112);
  border-radius: 22px 22px 22px 22px;

  cursor: pointer;
}
.submitBtnContainer_layout {
  position: relative;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subnitText {
  @include h-center-content;
  font: $font_type2;
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 0px;
  white-space: nowrap;
}
.subnitText_layout {
  position: relative;
  height: min-content;
}



.selectItem {
  @include flex-column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.058823529411764705);
  border-radius: 10px 10px 10px 10px;

  cursor: pointer;
}
.selectItem_layout {
  position: relative;
  flex: 0 0 352px;
  height: min-content;
}
.contentFlex1 {
  display: flex;
}
.contentFlex1_layout {
  position: relative;
  height: min-content;
  width: 71.59%;
  margin: 7px auto;
  @include tn {
    width: 92.65%;
  }
}
.contentFlex_item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
}
.toggle {
  background: var(--src) center center / contain no-repeat;
}
.toggle_layout {
  position: relative;
  height: 41px;
  width: 44px;
  min-width: 44px;
  @include tn {
    height: 40px;
    width: 40px;
    min-width: 40px;
    margin: 0px;
  }
}
.contentFlex_spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  @include md {
    flex: 1 1 auto;
    min-width: unset;
  }
}
.contentFlex_item1 {
  @include flex-column;
  position: relative;
  flex: 1 0 64px;
}
.toggleText {
  @include v-center-content;
  justify-content: center;
  font: $font_type1;
  color: rgb(118, 89, 58);
  letter-spacing: 0px;
}
.toggleText_layout {
  position: relative;
  flex-grow: 1;
  min-height: 20px;
  flex-shrink: 0;
  min-width: 0px;
  width: fit-content;
}