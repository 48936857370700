@import "utils";

$chapterCourse: 500 24px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$courseTitle: 16px/1.2 "Noto Sans TC", Helvetica, Arial, serif;
$listTitle: 500 20px/1.2 "Noto Sans TC", Helvetica, Arial, serif;


.player {
  width: 100%;
  box-sizing: border-box;

  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 1000;

  &.fullScreen {
    top: 0;
    right: 0;
  }

  .playerOpenContainer {
    display: none;
    height: 100%;


    .playerScrollContainer {
      // 大螢幕的容器.
      overflow: visible;
      display: grid;
      height: 100%;
      grid-template-rows: 1fr;

      &.bigScreen {
        background-color: #e3ded8;
      }


      .bigScreenContainer {
        overflow: auto;
        overscroll-behavior: none;
        width: 100%;
        height: 100%;
        padding: 10px 20px;
        background-color: rgb(227, 222, 216);

        display: none;
        opacity: 0;
        transform: translateY(100%);
        grid-template-columns: 2fr 1fr;
        grid-gap: 20px;
        padding-bottom: 10px;

        @include sm {
          padding: 0px 0px 10px 0px;
        }

        @include md {
          grid-template-columns: 1fr;
          grid-template-rows: auto 1fr;
          overflow: auto;
          height: unset;
          margin-bottom: 85px;
        }

        // 影片容器.
        .leftContainer {
          position: relative;
          width: 100%;
          height: 100%;

          display: grid;
          grid-template-rows: auto 1fr;
          align-items: center;

          .infoContainer {
            height: min-content;
            display: grid;
            grid-template-columns: 1fr auto;

            .actions {
              display: flex;
              align-items: center;
              position: relative;

              .shareContainer {
                position: relative;
                .shareIcon {
                  cursor: pointer;

                  @include xxs {
                    width: 32px;
                    height: 32px;
                  }
                }

                .shareSelect {
                  position: absolute;
                  transform: translateY(100%);
                  bottom: 0;
                  right: 0;
                  display: none;
                  width: unset;
                  white-space: nowrap;
                  border: 1px solid #F4F4F4;
                  border-radius: 5px;
                  background: #fff;
                  z-index: 10002;

                  &.show {
                    display: block;
                  }

                  .shareChapter {
                    font-size: 14px;
                    padding: 10px;
                    cursor: pointer;
                    border-bottom: 1px solid #F4F4F4;
                    text-align: center;
                    background: #fff;
                    &:hover {
                      background: #f6f6f6;
                    }
                  }
                  .shareNow {
                    font-size: 14px;
                    padding: 10px;
                    cursor: pointer;
                    text-align: center;
                    background: #fff;
                    &:hover {
                      background: #f6f6f6;
                    }
                  }
                }
              }

              .favIcon {
                cursor: pointer;

                @include xxs {
                  width: 32px;
                  height: 32px;
                }
              }
            }

            @include sm {
              border-bottom: 1px solid #c8bdb0;
              margin-top: 15px;
              padding: 0px 20px;
            }

            .chapter {
              @include font-face($chapterCourse, rgb(117, 89, 58), 0px);

              @include xs {
                font-size: 22px;
                text-align: left;
              }

              @include xxs {
                font-size: 20px;
              }

              @include tn {
                font-size: 18px;
                position: relative;
                height: min-content;
              }
            }

            .course {
              position: relative;
              height: min-content;
              margin: 13.5px 0px 0px;
              margin-bottom: 20px;
              @include v-center-content;
              @include font-face($courseTitle, rgb(51, 51, 51), 0px);

              @include xxs {
                font-size: 14px;
                text-align: left;
              }

              @include tn {
                font-size: 12px;
                margin: 11px 0px 20px;
              }
            }
          }

          .bgBlack {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #000;
            overflow: hidden;
          }

          .mediaContainer {
            width: auto;
            height: auto;
            position: relative;
            box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09803921568627451);
            background-color: #f2f2f2;

            &.mediaBaseWidth {
              padding-top: 56.25%;
              width: 100%;
            }

            &.mediaBaseHeight {
              padding-left: 100%;
              height: 100%;
              max-height: 100%;
              background: #000;
            }

            @include md {
              width: 100%;
              padding-top: 56.25%;

              &.isVideo {
                padding-top: 0%;
              }
            }

            .imgContainer {
              position: absolute;
              top: 0;
              left: 12.5%;
              width: 75%;
              height: 100%;
              display: none;
              margin: 0 auto;

              &.active {
                display: block;
              }

              .thumbnail {
                position: relative;
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center center;
                display: none;

                &.active {
                  display: block;
                }
              }
            }

            .audioImgContainer {
              position: absolute;
              top: 0;
              left: 12.5%;
              height: 100%;
              width: 75%;
              display: none;
              margin: 0 auto;

              &.mediaBaseHeight {}

              &.active {
                display: block;
              }

              .thumbnail {
                position: absolute;
                top: 0;
                left: 0;
                width: 75%;
                object-fit: cover;
                object-position: center center;
                display: none;

                &.active {
                  display: block;
                }
              }

              .disk {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 75%;
                background-color: #f2f2f2;
                background: var(--src) center center / contain no-repeat;
              }
            }

            .audiobookImgContainer {
              position: absolute;
              top: 0;
              left: 12.5%;
              height: 100%;
              width: 75%;
              display: none;
              overflow: hidden;

              margin: 0 auto;

              background-color: #f2f2f2;

              &.active {
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .thumbnail {
                position: absolute;
                top: 0;
                height: 100%;
                object-fit: cover;
                object-position: center top;
                display: none;

                &.active {
                  display: block;
                }
              }
            }

            .video {
              display: none;
              z-index: 10000;
              background-color: #000;

              &.active {
                display: flex;
              }
            }
          }
        }

        // 播放清單容器
        .rightContainer {
          width: 100%;
          height: 100%;
          display: grid;
          grid-template-rows: auto 1fr;
          overflow: hidden;

          @include md {
            height: unset;
            overflow: visible;
          }

          @include sm {
            padding: 0px 20px 10px;
          }

          .listTitle {
            @include font-face($listTitle, rgb(117, 89, 58), 0px);

            @include xs {
              text-align: left;
            }

            @include xxs {
              font-size: 18px;
            }

            @include tn {
              font-size: 16px;
            }

            position: relative;
            height: min-content;
            margin-bottom: 20px;
          }

          .listContainer {
            background-color: #fff;
            border-radius: 10px;
            max-height: 100%;
            height: 100%;
            overflow: scroll;
            overscroll-behavior: none;
            padding-bottom: 10px;

            .item {
              display: flex;
              justify-content: space-around;
              border-bottom: 2px solid #ededed;
            }

            @include md {
              max-height: unset;
              height: min-content;
            }
          }

        }
      }

      .controlContainer {
        height: 125px;
        display: flex;
        align-items: center;
      }
    }
  }

  .curtain {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    z-index: 1;
  }
}