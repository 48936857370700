.menu {
  width: 100%;
  height: 100%;

  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;

  display: none;

  &.active {
    display: block;
  }

  .backgroundClose {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
  }
}
